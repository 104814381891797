

























import Vue from 'vue'
import { DB } from '@/firebase/db'
import SearchChannel from '@/components/SearchChannel.vue'

export default Vue.extend({
  name: 'SortedChannelList',

  data: () => ({
    channel: [] as any,
    tag: ''
  }),

  components: {
    SearchChannel
  },

  created: function() {
    this.tag = this.$route.params.id
    if(this.tag) {
      this.fetchSortedVideoData(this.tag)
    }
    window.scrollTo({
      top: 0
    })
  },

  methods: {
    fetchSortedVideoData(tag: string) {
      const channelArray = [] as any
      const arr = [] as any
      DB.collection('channels')
      .where("tags", "array-contains", tag )
      .orderBy('subscriberCount', 'desc')
      .get()
      .then(querySnapShot => {
        querySnapShot.forEach(doc => {
          channelArray.push(doc.data())
        })
      })
      .then(() => {
        this.channel = channelArray
      })
    },

    changeParms(tag: string) {
      this.channel = []
      this.tag = tag
      this.fetchSortedVideoData(tag)
    },
  }
})
