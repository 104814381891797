































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'

export default Vue.extend({
  name: 'FeatureProduct',

  data: () => ({
    categoryName: '',
    videos: {},
    products: [] as any,
    feature: '',
    brands: [] as any,
    categoryNames: [] as any,
    categoryIds: [] as any,
    sortedBrands: '',
    sortedCategory: '',
    tabColor: '#19C0C9',
    tabSliderColor: '#19C0C9',
    params: 'times',
    order: 'desc',
    isFetch: true,
    tabs: [
      {
        name: '人気順',
        params: 'times',
        order: 'desc'
      },
      {
        name: '安い順',
        params: 'price',
        order: 'asc'
      },
      {
        name: '高い順',
        params: 'price',
        order: 'desc'
      }
    ]
  }),

  computed: {
    categoryArray: function() {
      if (this.$route.params.id == 'skincare') {
        return [7, 26, 27, 28, 30, 31, 62, 68, 72]
      }
      if (this.$route.params.id == 'sunscreenFace') {
        return [67, 28, 61]
      }
      if (this.$route.params.id == 'concealer') {
        return [11]
      }
      if (this.$route.params.id == 'foundation') {
        return [21, 25, 29, 35, 60, 61]
      }
      if (this.$route.params.id == 'blush') {
        return [16, 21, 60]
      }
      if (this.$route.params.id == 'eyeshadow') {
        return [1]
      }
      if (this.$route.params.id == 'mascara') {
        return [51, 52]
      }
      if (this.$route.params.id == 'eyeliner') {
        return [3]
      }
      if (this.$route.params.id == 'eyebrow') {
        return [2]
      }
      if (this.$route.params.id == 'lipGloss') {
        return [58, 59]
      }
      if (this.$route.params.id == 'lipstick') {
        return [57, 58, 59]
      }
      if (this.$route.params.id == 'brushes') {
        return [33]
      }
      if (this.$route.params.id == 'nailPolish') {
        return [55]
      }
      if (this.$route.params.id == 'otherGoods') {
        return [10, 24, 32, 54, 66, 71, 76, 77]
      }
      else {
        return [54]
      }
    }
  },

  mounted: function() {
    this.feature = this.$route.params.id
    this.fetchProducts('times', 'desc', this.$route.params.id)
    this.setCategoryName()
    window.scrollTo({
      top: 0
    })
  },
  
   watch: {
    sortedBrands: function() {
      this.fetchSortedProducts(this.params, this.order, this.$route.params.id)
    },
    
    sortedCategory: function() {
      this.fetchSortedProducts(this.params, this.order, this.$route.params.id)
    }
  },

  methods: {

    fetchProducts (params: string, order: string, tag: string): any {
      this.products = []
      this.isFetch = false
      this.params = params
      this.order = order
      const products = [] as any
      const brands = [] as any
      const categoryNames = [] as any
      const categoryIds = [] as any
      const option = order == 'desc' ? 'desc' : 'asc'
      DB.collection('videos')
      .where("tags", "array-contains", tag )
      .orderBy(params, option)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().times > 1) {
            products.push(doc.data())
            brands.push(doc.data().brand)
            categoryNames.push(doc.data().categoryName)
            categoryIds.push(doc.data().categoryId)
          }
        })
      })
      .then(() => {
        if (this.sortedBrands == ''){
          this.products = [...new Map(products.map((v: any) => [v.productId, v])).values()]
        }
        if (this.sortedBrands != ''){
          this.products = products.filter((doc: any) => {
            return (doc.brand === this.sortedBrands)
          })
        }
        this.brands = Array.from(new Set(brands)).sort()
        this.categoryNames = Array.from(new Set(categoryNames)).sort()
        this.categoryIds = Array.from(new Set(categoryIds)).sort()
        this.isFetch = true
      })
    },

    fetchSortedProducts (params: string, order: string, tag: string): any {
      this.products = []
      this.isFetch = false
      this.params = params
      this.order = order
      const products = [] as any
      const brands = [] as any
      const categoryNames = [] as any
      const categoryIds = [] as any
      const option = order == 'desc' ? 'desc' : 'asc'
      DB.collection('videos')
      .where("tags", "array-contains", tag )
      .orderBy(params, option)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (this.sortedCategory == '') {
            if (doc.data().times != 0) {
              products.push(doc.data())
              brands.push(doc.data().brand)
              categoryNames.push(doc.data().categoryName)
              categoryIds.push(doc.data().categoryId)
            }
          }
          if (this.sortedCategory != '') {
            if (doc.data().times != 0 && doc.data().categoryName == this.sortedCategory) {
              products.push(doc.data())
              brands.push(doc.data().brand)
              categoryNames.push(doc.data().categoryName)
              categoryIds.push(doc.data().categoryId)
            }
          }
        })
      })
      .then(() => {
        if (this.sortedBrands == ''){
          this.products = [...new Map(products.map((v: any) => [v.productId, v])).values()]
          this.brands = Array.from(new Set(brands)).sort()
          this.categoryNames = Array.from(new Set(categoryNames)).sort()
          this.categoryIds = Array.from(new Set(categoryIds)).sort()
        }

        if (this.sortedBrands != ''){
          const brandArr = [] as any
          const categoryArray = [] as any
          const categoryIds = [] as any
          const sortedBrandsproducts = products.filter((doc: any) => {
            return (doc.brand === this.sortedBrands)
          })
          this.products = [...new Map(sortedBrandsproducts.map((v: any) => [v.productId, v])).values()]
          sortedBrandsproducts.forEach((doc: any) => {
            brandArr.push(doc.brand)
            categoryArray.push(doc.categoryName)
            categoryIds.push(doc.categoryId)
          })
          this.brands = Array.from(new Set(brandArr)).sort()
          this.categoryNames = Array.from(new Set(categoryArray)).sort()
          this.categoryIds = Array.from(new Set(categoryIds)).sort()
        }
        this.isFetch = true
      })
    },

    setCategoryName() {
      if (this.$route.params.id == 'skincare') {
        this.categoryName = 'スキンケア'
      }
      if (this.$route.params.id == 'sunscreenFace') {
        this.categoryName = 'UVケア'
      }
      if (this.$route.params.id == 'concealer') {
        this.categoryName = 'コンシーラー'
      }
      if (this.$route.params.id == 'foundation') {
        this.categoryName = 'ファンデーション'
      }
      if (this.$route.params.id == 'blush') {
        this.categoryName = 'チーク'
      }
      if (this.$route.params.id == 'eyeshadow') {
        this.categoryName = 'アイシャドウ'
      }
      if (this.$route.params.id == 'mascara') {
        this.categoryName = 'マスカラ'
      }
      if (this.$route.params.id == 'eyeliner') {
        this.categoryName = 'アイライナー'
      }
      if (this.$route.params.id == 'eyebrow') {
        this.categoryName = 'アイブロウ'
      }
      if (this.$route.params.id == 'lipGloss') {
        this.categoryName = 'リップグロス'
      }
      if (this.$route.params.id == 'lipstick') {
        this.categoryName = 'リップ'
      }
      if (this.$route.params.id == 'brushes') {
        this.categoryName = 'ブラシ'
      }
      if (this.$route.params.id == 'nailPolish') {
        this.categoryName = 'マニキュア'
      }
      if (this.$route.params.id == 'otherGoods') {
        this.categoryName = '美容グッズ'
      }
    },

    initialize() {
      this.sortedBrands = ''
      this.sortedCategory = ''
    },

    routerPush(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    }
  }
})
