











































































import Vue from 'vue'
import firebase from 'firebase'
// import colors from 'vuetify/es5/util/colors'

export default Vue.extend({
  name: 'Header',

  data: () => ({
    tabColor: '#8c8c8c',
    tabSliderColor: '#00000000',
    isAnonymous: false,
    tabs: [
      {
        name: 'トップ',
        route: 'Top'
      },
      {
        name: 'コスメ動画',
        route: 'VideoList'
      },
      // {
      //   name: 'YouTuber',
      //   route: 'ChannelList'
      // },
      {
        name: 'ランキング',
        route: 'RankingList'
      },
      {
        name: 'コスメ',
        route: 'Category'
      }
    ],
    menuItems: [
      {
        name: 'サイトポリシー',
        to: 'Sitepolicy'
      },
      {
        name: '利用規約',
        to: 'TermsOfService'
      },
      {
        name: 'プライバシーポリシー',
        to: 'PrivacyPolicy'
      }
    ],
  }),

  methods: {
    routerPush(view: string): void {
      this.tabColor = '#19C0C9'
      this.tabSliderColor = '#19C0C9'
      if (view == 'TermsOfService' ||
          view == 'PrivacyPolicy' ||
          view == 'Top' ||
          view == 'Search'
          ) {
        this.tabColor = '#8c8c8c'
        this.tabSliderColor = '#00000000'
      }
      this.$router.push({ name: view })
    },

    onAuthStateChanged(): void {
      firebase.auth().onAuthStateChanged( user => {
        if (user && !user.isAnonymous) {
          this.routerPush('UsersProduct')
          this.tabColor = '#8c8c8c'
          this.tabSliderColor = '#00000000'
        } else {
          this.routerPush('Signin')
          this.tabColor = '#8c8c8c'
          this.tabSliderColor = '#00000000'
        }
      })
    }
  }
})
