




































import Vue from 'vue'
import { DB } from '@/firebase/db'
import firebase from 'firebase'


export default Vue.extend({
  name: 'YoutubePlayer',

  props: {
    handleVideos: {} as any,
    handleTime: String,
    handleFixed: Boolean,
    handleProductid: String
  },

  data: () => ({
    timerId: 0,
    width: window.innerWidth,
    height: window.innerHeight
  }),

  watch: {
    handleVideos: function(newVal, oldVal){
      setTimeout(()=> {
        this.ready()
      },400)
    }
  },

  mounted: function () {
    window.addEventListener('resize', this.handleResize)
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize: function() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },

    playerVars() {
      const convertedTime = this.convertTime(this.handleTime)
      const playerVars = {
        playsinline: 1,
        autoplay: 1,
        start: convertedTime,
        // end: convertedTime+30,
        enablejsapi: 1,
        modestbranding: 1,
        fs: 0,
        controls: 1,
        origin: 'https://cramel.jp'
      }
      return playerVars
    },

    convertTime(time: string) {
      const timeArray = time.split(':')
      return Number(timeArray[0]) * 60 + Number(timeArray[1])
    },

    ready() {
      // let count = 0
      // clearInterval(this.timerId)
      const youtubePlayer: any = this.$refs.youtube
      // console.log(youtubePlayer)
      // console.log(this.playerVars().start)
      this.setPlayCountAtCramel(this.handleVideos)
      this.$ga.event('playVideoAtYoutubePlayer', 'push')
      youtubePlayer.player.mute()
      youtubePlayer.player.seekTo(this.playerVars().start)
      youtubePlayer.player.playVideo()
      // this.timerId = setInterval(() => {
      //   count+=1
      //   if(count<1000 && this.$route.path.includes('individualproductvideo')){
      //     youtubePlayer.player.seekTo(this.playerVars().start)
      //     youtubePlayer.player.playVideo()
      //     console.log(count)
      //   } else {
      //     console.log('stop')
      //     clearInterval(this.timerId)
      //   }
      // }, 30000)
    },

    setPlayCountAtCramel(video: any) {
      const increment = firebase.firestore.FieldValue.increment(1)
      const docRefProducts = DB.collection('products').doc(String(this.handleProductid))
      const docRefVideoUnique = DB.collection('videoUnique').doc(String(video.id))
      docRefProducts
      .set({
        viwesAtCramel: increment
      },
      { 
        merge: true
      })

      docRefVideoUnique
      .set({
        viwesAtCramel: increment
      },
      { 
        merge: true
      })
    },
  }
})
