










































































































































import Vue from 'vue'
// import CarouselImage from '@/components/CarouselImage.vue'
import store from '@/store'
import { DB } from '@/firebase/db'
import firebase from 'firebase'
import ResultSpring from '@/components/ResultSpring.vue'
import ResultSummer from '@/components/ResultSummer.vue'
import ResultAutumn from '@/components/ResultAutumn.vue'
import ResultWinter from '@/components/ResultWinter.vue'

export default Vue.extend({
  name: 'PersonalColorAnalysis',

  components: {
    ResultSpring,
    ResultSummer,
    ResultAutumn,
    ResultWinter
  },

  data: () => ({
    topBannerimage: require('@/assets/images/mv.jpg'),
    personelColorItems: [
      {
        name: '# 春タイプ',
        id: 'spring',
        base: 'Yelow base',
        cardClass: 'pt-5 ml-2 mr-1 card-border',
        textClass: 'text-h7 font-weight-bold text-spring mb-0'
      },
      {
        name: '# 夏タイプ',
        id: 'summer',
        base: 'Blue base',
        cardClass: 'pt-5 ml-1 mr-2 card-border',
        textClass: 'text-h7 font-weight-bold text-summer mb-0'
      },
      {
        name: '# 秋タイプ',
        id: 'autumn',
        base: 'Yelow base',
        cardClass: 'pt-5 ml-2 mr-1 card-border',
        textClass: 'text-h7 font-weight-bold text-autumn mb-0'
      },
      {
        name: '# 冬タイプ',
        id: 'winter',
        base: 'Blue base',
        cardClass: 'pt-5 ml-1 mr-2 card-border',
        textClass: 'text-h7 font-weight-bold text-winter mb-0'
      }
    ]
  }),

  created: function() {
    window.scrollTo({
      top: 0
    })
  },

  methods: {

    scrollToSection(id: string) {
      const element: any = document.getElementById(id)
      element.scrollIntoView({
        behavior: 'smooth'
      })
    },

    pushToPersonalcolorAnalysisQuestion() {
      this.$router.push({ name: 'PersonalColorAnalysisQuestion'})
    },
 
    openWindow (url: string) {
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    pushToChannelPage(v: number): void {
      this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToProductPage(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToSortedProductPage(v: string): void {
      this.$router.push({ name: 'SortedProductList', params: { id: v }})
    },

    pushToFeatureProduct(v: string): void {
      this.$router.push({ name: 'FeatureProduct', params: { id: v }})
    },

    pushToVideoPage(v: number): void {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    },

    pushToBrandProductPage(v: number): void {
      this.$router.push({ name: 'BrandProduct', params: { id: v.toString() }})
    }
  }
})
