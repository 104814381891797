























































































import Vue from 'vue'
// import CarouselImage from '@/components/CarouselImage.vue'
import store from '@/store'
import { DB } from '@/firebase/db'
import firebase from 'firebase'
import ResultAutumn from '@/components/ResultAutumn.vue'

export default Vue.extend({
  name: 'PersonalColorResultAutumn',

  components: {
    ResultAutumn
  },

  props:['from'],

  data: () => ({
    topBannerimage: require('@/assets/images/mv.jpg'),
    personelColorItems: [
      {
        name: '# 春タイプ',
        id: 'spring',
        base: 'Yelow base',
        cardClass: 'pt-5 ml-2 mr-1 card-border',
        textClass: 'text-h7 font-weight-bold text-spring mb-0'
      },
      {
        name: '# 夏タイプ',
        id: 'summer',
        base: 'Blue base',
        cardClass: 'pt-5 ml-1 mr-2 card-border',
        textClass: 'text-h7 font-weight-bold text-summer mb-0'
      },
      {
        name: '# 秋タイプ',
        id: 'autumn',
        base: 'Yelow base',
        cardClass: 'pt-5 ml-2 mr-1 card-border',
        textClass: 'text-h7 font-weight-bold text-autumn mb-0'
      },
      {
        name: '# 冬タイプ',
        id: 'winter',
        base: 'Blue base',
        cardClass: 'pt-5 ml-1 mr-2 card-border',
        textClass: 'text-h7 font-weight-bold text-winter mb-0'
      }
    ]
  }),

  methods: {

    scrollToSection(id: string) {
      if(id == 'autumn'){
        const element: any = document.getElementById(id)
        element.scrollIntoView({
          behavior: 'smooth'
        })
      }
      if(id == 'spring'){
        this.$router.push({ name: 'PersonalColorResultSpring'})
      }
      if(id == 'summer'){
        this.$router.push({ name: 'PersonalColorResultSummer'})
      }
      if(id == 'winter'){
        this.$router.push({ name: 'PersonalColorResultWinter'})
      }
    },
 
    openWindow (url: string) {
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    pushToPersonalcolorAnalysis() {
      this.$router.push({ name: 'PersonalColorAnalysis'})
    },

    pushToChannelPage(v: number): void {
      this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToProductPage(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToSortedProductPage(v: string): void {
      this.$router.push({ name: 'SortedProductList', params: { id: v }})
    },

    pushToFeatureProduct(v: string): void {
      this.$router.push({ name: 'FeatureProduct', params: { id: v }})
    },

    pushToVideoPage(v: number): void {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    },

    pushToBrandProductPage(v: number): void {
      this.$router.push({ name: 'BrandProduct', params: { id: v.toString() }})
    },

    pushToOtherPage(name: string): void {
      this.$router.push({ name: name })
    },

    pushToSortedChannelList(tag: string) {
      this.$router.push({
        name: 'SortedChannelList',
        params: {
          tag: tag
        }
      })
    }
  }
})
