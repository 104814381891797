




































































































import Vue from 'vue'
import { DB } from '@/firebase/db'

export default Vue.extend({
  name: 'RankingList',

  data: () => ({
      count: 0,
      categories: [] as any
  }),

  created: function () {
    this.fetchCategories()
  },

  methods: {
    fetchProducts (): void {
      this.categories.forEach((e: any, i: number)=> {
        const array = [] as any
        DB.collection('products')
        .where('categoryId', '==', e.id)
        .orderBy('times', 'desc')
        .limit(3)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            array.push(doc.data())
          })
          this.$set(this.categories[i], "products", array)
          this.count++
        })
      })
    },

    fetchCategories (): void {
      const array = [] as any
      DB.collection('categories')
      .orderBy('time', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          array.push(doc.data())
        })
        this.categories = array
      })
      .then(() => {
        this.fetchProducts()
      })
    },

    routerPush(v: number, name: string) {
      this.$router.push({ name: name, params: { id: v.toString() }})
    }

  }
})
