











































































































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import { Auth } from '@/firebase/auth'
import firebase from 'firebase'

interface User {
  uid: string;
  createdAt: Date;
  thumbnail: string;
  name: string;
  email: string;
  birthYear: number;
  birthMonth: number;
  birthDay: number;
  selfIntroduction: string;
}

export default Vue.extend({
  name: 'Signup',
  data: () => ({
    name: '',
    email: '',
    password: '',
    birthYear: 1990,
    birthMonth: 1,
    birthDay: 1,
    selfIntroduction: '',
    readyEmail: false,
    readyPassword: false,
    showPassword: false,
    rules: {
      required: (v: string) => !!v || '未入力です。',
      min: (v: string) => v.length >= 8 || '8文字以上必要です。',
      emailMatch: () => (`The email and password you entered don't match`),
      email: (v: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'メールアドレスの形式が正しくありません。'
      },
      password: (v: string) => {
        const pattern = /^[0-9a-zA-Z]*$/
        return pattern.test(v) || '半角英数字で入力して下さい。'
      }
    },
    errorMessage: "",
    ready: true
  }),

  created: function () {
    this.ready = true
  },

  watch: {
    email: function() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (this.email != '' && pattern.test(this.email)) {
        this.readyEmail = true
      } else {
        this.readyEmail = false
      }
    },
    
    password: function() {
      const pattern = /^[0-9a-zA-Z]*$/
      if (this.password.length >= 8 && pattern.test(this.password)) {
        this.readyPassword = true
      } else {
        this.readyPassword = false
      }
    },
  },

  computed: {
    readySignupWithEmail: function() {
      return this.name && this.readyEmail && this.readyPassword ? true : false
    },

    readyEmail: function() {
      return this.readyEmail
    },

    generateYear(){
      const yearArray: number[] = []
      for (let i = 1940; i <= 2021; i++) {
        const v: any = {
          year: i,
          value: i
        }
        yearArray.push(v)
      }
      return yearArray
    },

    generateMonth(){
      const monthArray: number[] = []
      for (let i = 1; i <= 12; i++) {
        const v: any = {
          month: i,
          value: i
        }
        monthArray.push(v)
      }
      return monthArray
    },

    generateDay(){
      const dayArray: number[] = []
      for (let i = 1; i <= 31; i++) {
        const v: any = {
          day: i,
          value: i
        }
        dayArray.push(v)
      }
      return dayArray
    },
  },

  methods: {
    signup() {
      this.ready = false
      Auth.createUserWithEmailAndPassword(this.email, this.password)
      .then(result => {
        if(result.user){
          this.setUserDataToFirestore(result.user)
        }
      })
      .catch(error => {
        this.ready = true
        console.log(error)
        if (error.code == "auth/email-already-in-use") {
          this.errorMessage = "このメールアドレスは既に登録されています。別のメールアドレスで再度お試し下さい。"
        }
      }).
      finally(() =>{
        this.ready = true
      })
    },

    signupBySnsAuth() {
      DB.collection('users')
      .doc(this.$route.params.id)
      .update(
        {
          birthDay: this.birthDay,
          birthMonth: this.birthMonth,
          birthYear: this.birthYear,
          email: this.email,
          selfIntroduction: this.selfIntroduction
        }
      ).then(() => {
        this.pushToUserProduct(this.$route.params.id)
      })
    },

    setUserDataToFirestore(data: firebase.User) {
      const uid = data.uid
      const userData: User = {
        uid: uid,
        createdAt: new Date(),
        thumbnail: "",
        name: this.name,
        email: this.email,
        birthYear: this.birthYear,
        birthMonth: this.birthMonth,
        birthDay: this.birthDay,
        selfIntroduction: this.selfIntroduction
      }
      DB.collection('users')
      .doc(data.uid)
      .set(userData)
      .then(() => {
        this.pushToUserProduct(uid)
      })
    },

    pushToUserProduct(v: string) {
      this.$router.push({ name: 'UsersProduct' })
    },
  }
})
