



































































































































































































































































































































import Vue from 'vue'
import Flickity from 'vue-flickity-2';
import 'vue-flickity-2/dist/vue-flickity-2.css';


export default Vue.extend({
  name: 'CarouselVueFlickity',

  components: {
    Flickity
  },

  data: () => ({
    flickityOptions: {
      pageDots: true,
      prevNextButtons: false,
      wrapAround: true
    },
  }),

  props: {
    handleProducts: [] as any
  },

  methods: {
    pushToBrandProductPage(v: number): void {
      this.$router.push({ name: 'BrandProduct', params: { id: v.toString() }})
    },
    pushToProduct(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },
  }
})
