


















































import Vue from 'vue'


export default Vue.extend({
  name: 'SearchChannel',

  props: {
    handleChannels: [] as any
  },

  data: () => ({
  }),

  methods: {
    pushChannelPage(v: number) {
      console.log('')
      // this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    changeParms(tag: string) {
      this.$emit('changeParms', tag)
    }
  }
})
