













































































































































































































































































































































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import SuggestVideoList from '@/components/SuggestVideoList.vue'
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default Vue.extend({
  name: 'YoutubersProduct',

  components: {
    SuggestVideoList,
    Carousel3d,
    Slide
  },

  data: () => ({
    productIdArray: [] as any,
    videoUniques: [] as any,
    suggestedVideoUniques: [] as any,
    products: [] as any,
    videoProducts: [] as any,
    categories: [] as any,
    channelInfo: {} as any,
    isFetch: false,
    currentTab: 'cosme',
    tabColor: '#19C0C9',
    tabSliderColor: '#19C0C9',
    tabs: [
      {
        name: 'コスメ一覧',
        value: 'cosme'
      },
      {
        name: '動画',
        value: 'video'
      }
    ],
    playerVars: {
      playsinline: 1,
      autoplay: 0,
      start: 0
    }
  }),

  created: function() {
    this.fetchVideos()
    this.fetchChannels()
    this.fetchVideoUnique()
    this.fetchSuggestedVideoUnique(this.$route.params.id)
    window.scrollTo({
      top: 0
    })
  },

  methods: {

    fetchVideos (): void {
      const productIdArray = [] as any
      const productArray = [] as any
      DB.collection('videos')
      .where('channelId', '==', this.$route.params.id)
      .orderBy('sortId', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          productIdArray.push(doc.data().productId)
          if(doc.data().comment){
            productArray.push(doc.data())
          }
        })
        this.productIdArray = productIdArray
        // this.videoProducts = productArray
      })
      .then(() => {
        this.fetchProducts()
      })
    },

    fetchVideoUnique (): void {
      const videoUniqueArray = [] as any
      DB.collection('videoUnique')
      .where('channelId', '==', this.$route.params.id)
      .orderBy('publishedAt', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          videoUniqueArray.push(doc.data())
        })
        this.videoUniques = videoUniqueArray
      })
      .then(() => {
        // console.log('test')
        this.fetchVideoProduct(this.videoUniques[0].id)
      })
    },

    fetchProducts (): void {
      const sortedproductIdArray = Array.from(new Set(this.productIdArray))
      const productArray = [] as any
      const categoryArray = [] as any
      sortedproductIdArray.forEach((v: any) => {
        DB.collection('products')
        .where('id', '==', v)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const categoryName = doc.data().categoryName
            productArray.push(doc.data())
            
            categoryArray.push(categoryName)
          })
        })
        .then(() => {
          this.categories = Array.from(new Set(categoryArray))
          const products = [] as any
          this.categories.forEach(() =>{
            products.push([])
          })
          productArray.forEach((e: any) => {
            products[this.categories.indexOf(e.categoryName)].push(e)
            products[this.categories.indexOf(e.categoryName)].sort((a: any, b: any) => {
              if (a.review > b.review) {
                return -1
              } else {
                return 1
              }
            })
          })
          this.products = products
        })
      })
    },

    fetchVideoProduct (id: number): void {
      const videoId = id
      const videoProductArray = [] as any
      DB.collection('videos')
      .where('videoId', '==', videoId)
      .orderBy('id', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          videoProductArray.push(doc.data())
        })
        this.videoProducts = videoProductArray
      })
    },

    fetchChannels (): any {
      DB.collection('channels')
      .where('id', '==', this.$route.params.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.channelInfo = doc.data()
        })
      })
      .then(() => {
        document.title = `【${this.channelInfo.name}の紹介コスメ】人気YouTuberの動画レビューまとめ｜Cramel（クラメル）`
      })
    },

    publishedAt(datetime: Date) {
      const from = new Date(datetime);
      const diff = new Date().getTime() - from.getTime();
      const elapsed = new Date(diff);

      if (elapsed.getUTCFullYear() - 1970) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
      } else if (elapsed.getUTCMonth()) {
        return elapsed.getUTCMonth() + 'ヶ月前';
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + '日前';
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + '時間前';
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + '分前';
      } else {
        return elapsed.getUTCSeconds() + 'たった今';
      }
    },

    convertTimeStamp(date: any) {
      const year = new Date(date).getFullYear()
      const month = new Date(date).getMonth() + 1
      const day = new Date(date).getDate()
      return year + '/' + month + '/' + day
    },


    fetchSuggestedVideoUnique (channelId: string): void {
      const videoUniqueArray = [] as any
      DB.collection('videoUnique')
      .orderBy('publishedAt', 'desc')
      .limit(20)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          videoUniqueArray.push(doc.data())
        })
        this.suggestedVideoUniques = videoUniqueArray
      })
      .then(() => {
        this.isFetch = true
      })
    },

    shuffleFisherYates(arr: any[]): any[] {
      const newArray = arr
      for(let i = newArray.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * (i + 1));
        const tmp = newArray[i];
        newArray[i] = newArray[j];
        newArray[j] = tmp;
      }
      return newArray
    },

    onAfterSlideChange(id: number) {
      // console.log(id)
      this.fetchVideoProduct(this.videoUniques[id].id)
    },

    pushToProduct(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToIndividualProductVideo(id: string, time: string, productId: string): void {
      const params = `${id}&${time}&${productId}`
      this.$router.push({ name: 'IndividualProductVideo', params: { id: params.toString() }})
    },

    pushToVideoPage(v: number): void {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    },


    pushToSortedChannelList(tag: string) {
      this.$router.push({ name: 'SortedChannelList', params: { id: tag }})
    },

    openYoutube (id: string) {
      const youtubelink = `https://www.youtube.com/channel/${id}`
      window.open(youtubelink, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    }
  }
})
