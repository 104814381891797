





























import Vue from 'vue'


export default Vue.extend({
  name: 'CarouselImage',

  data: () => ({
      images: [
        require('@/assets/images/featureCosme.001.jpeg'),
        require('@/assets/images/personalColorImage.jpeg'),
        // require('@/assets/images/IMG_1528.png')
      ]
  }),

  methods: {
    pushToPersonalcolorAnalysis(i: number) {
      if(i == 1) {
        this.$router.push({ name: 'PersonalColorAnalysis'})
      }
    }
  }
})
