





























































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import { Auth } from '@/firebase/auth'
import firebase from 'firebase'

interface User {
  uid: string;
  createdAt: Date;
  thumbnail: string;
  name: string;
  email: string;
  birthYear: number;
  birthMonth: number;
  birthDay: number;
}

export default Vue.extend({
  name: 'Signin',

  data: () => ({
    user: {} as any,
    readyEmail: false,
    readyPassword: false,
    email: '',
    password: '',
    showPassword: false,
    isAnonymous: false,
    rules: {
      required: (v: any) => !!v || '未入力です。',
      min: (v: string) => v.length >= 8 || '8文字以上必要です。',
      emailMatch: () => (`The email and password you entered don't match`),
      email: (v: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'メールアドレスの形式が正しくありません。'
      }
    },
    errorMessage: "",
    ready: true
  }),

  created: function () {
    this.ready = true
    this.onAuthStateChanged()
  },

  watch: {
    email: function() {
      if (this.email != '') {
        this.readyEmail = true
      } else {
        this.readyEmail = false
      }
    },
    password: function() {
      if (this.password != '') {
        this.readyPassword = true
      } else {
        this.readyPassword = false
      }
    },
  },

  computed: {
    readySigninWithEmail: function() {
      return this.readyEmail && this.readyPassword ? true : false
    }
  },

  methods: {

    onAuthStateChanged() {
      firebase.auth().onAuthStateChanged( user => {
        if (user) {
          this.user = user
          this.isAnonymous = user.isAnonymous
        } else {
          firebase.auth().signInAnonymously()
            .then(() => {
              firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                  this.user = user
                  this.isAnonymous = user.isAnonymous
                }
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },

    signin(provider: string) {
      this.ready = false
      const twitterAuthProvider = new firebase.auth.TwitterAuthProvider()
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

      if (provider == 'email') {
        Auth.signInWithEmailAndPassword(this.email, this.password)
        .then( result => {
          // console.log(result.user)
        })
        .catch( error => {
          this.ready = true
          // console.log(error)
          if (error.code == "auth/user-not-found") {
            this.errorMessage = "このメールアドレスではアカウントが登録されていません。"
          }
          if (error.code == "auth/wrong-password") {
            this.errorMessage = "パスワードが間違っているか、アカウント作成の際にSNSでのログインを選択されているので、SNSログインをお試しください。"
          }
          if (error.code == "auth/too-many-requests") {
            this.errorMessage = "このアカウントはログイン試行が上限に達した為一時的に無効化されています。パスワードをリセットするか、時間をおいてから再度ログインして下さい。"
          }
        })
        .finally (() => {
          this.ready = true
        })
      }

      if (provider == 'twitter') {
        Auth.signInWithPopup(twitterAuthProvider)
        .then( result => {
          if (result.user && result.additionalUserInfo) {
            this.checkIfUserDataExistsInFirestore(provider, result.user.uid, result.user, result.additionalUserInfo)
          } else {
            this.ready = true
            alert("ログインエラー。もう一度お試し下さい。")
          }
        })
        .catch( error => {
          this.ready = true
          console.log(error)
          if (error.code == "auth/account-exists-with-different-credential") {
            this.errorMessage = "同じメールアドレスのアカウントが既に存在しております、SNSでのログインをお試し下さい。"
          }
        })
      }

      if (provider == 'google') {
        Auth.signInWithPopup(googleAuthProvider)
        .then( result => {
          if (result.user && result.additionalUserInfo) {
            this.checkIfUserDataExistsInFirestore(provider, result.user.uid, result.user, result.additionalUserInfo)
          } else {
            this.ready = true
            alert("ログインエラー。もう一度お試し下さい。")
          }
        })
        .catch( error => {
          this.ready = true
          console.log(error)
          if (error.code == "auth/account-exists-with-different-credential") {
            this.errorMessage = "同じメールアドレスのアカウントが既に存在しております、SNSでのログインをお試し下さい。"
          }
        })
      }

    },

    linkWithCredential(provider: string){
      firebase.auth().onAuthStateChanged( user => {
        if (user) {
          this.user = user
          this.isAnonymous = user.isAnonymous

          this.ready = false
          const twitterAuthProvider = new firebase.auth.TwitterAuthProvider()
          const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
          const emailAuthProvider = new firebase.auth.EmailAuthProvider()

          if (provider == 'email') {
            const credential = firebase.auth.EmailAuthProvider.credential(this.email, this.password);
            Auth.signInWithEmailAndPassword(this.email, this.password)
            .then( result => {
              // console.log(result.user)
            })
            .catch( error => {
              this.ready = true
              // console.log(error)
              if (error.code == "auth/user-not-found") {
                this.errorMessage = "このメールアドレスではアカウントが登録されていません。"
              }
              if (error.code == "auth/wrong-password") {
                this.errorMessage = "パスワードが間違っているか、アカウント作成の際にSNSでのログインを選択されているので、SNSログインをお試しください。"
              }
              if (error.code == "auth/too-many-requests") {
                this.errorMessage = "このアカウントはログイン試行が上限に達した為一時的に無効化されています。パスワードをリセットするか、時間をおいてから再度ログインして下さい。"
              }
            })
            .finally (() => {
              this.ready = true
            })
          }

          if (provider == 'twitter') {
            user.linkWithPopup(twitterAuthProvider)
            .then( result => {
              if (result.user && result.additionalUserInfo) {
                this.checkIfUserDataExistsInFirestore(provider, result.user.uid, result.user, result.additionalUserInfo)
              } else {
                this.ready = true
                alert("ログインエラー。もう一度お試し下さい。")
              }
            })
            .catch( error => {
              this.ready = true
              console.log(error)
              if (error.code == "auth/account-exists-with-different-credential") {
                this.errorMessage = "同じメールアドレスのアカウントが既に存在しております、SNSでのログインをお試し下さい。"
              }
            })
          }

          if (provider == 'google') {
            user.linkWithPopup(googleAuthProvider)
            Auth.signInWithPopup(googleAuthProvider)
            .then( result => {
              if (result.user && result.additionalUserInfo) {
                this.checkIfUserDataExistsInFirestore(provider, result.user.uid, result.user, result.additionalUserInfo)
              } else {
                this.ready = true
                alert("ログインエラー。もう一度お試し下さい。")
              }
            })
            .catch( error => {
              this.ready = true
              console.log(error)
              if (error.code == "auth/account-exists-with-different-credential") {
                this.errorMessage = "同じメールアドレスのアカウントが既に存在しております、SNSでのログインをお試し下さい。"
              }
            })
          }
        }
      })
    },

    checkIfUserDataExistsInFirestore (provider: string, uid: string, data: firebase.User, additionalUserInfo: firebase.auth.AdditionalUserInfo): void {
      DB.collection('users')
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.updateUserImage(data, additionalUserInfo)
          this.pushToUserProduct(doc.id)
        } else {
          if (provider == 'twitter') {
            this.setTwitterUserDataToFirestore(data, additionalUserInfo)
          }
          if (provider == 'google') {
            this.setGoogleUserDataToFirestore(data)
          }
        }
      })
      .catch(error => {
        this.ready = true
        console.log(error)
      })
    },

    setTwitterUserDataToFirestore(user: firebase.User, additionalUserInfo: firebase.auth.AdditionalUserInfo) {
      const uid = user.uid
      const userData: User = {
        uid: uid,
        createdAt: new Date(),
        thumbnail: (additionalUserInfo as any).profile.profile_image_url_https ? (additionalUserInfo as any).profile.profile_image_url_https.replace('_normal', '') : '',
        name: user.displayName ? user.displayName : '',
        email: (additionalUserInfo as any).profile.email ? (additionalUserInfo as any).profile.email : '',
        birthYear: 1990,
        birthMonth: 1,
        birthDay:1
      }
      DB.collection('users')
      .doc(user.uid)
      .set(userData)
      .then(() => {
        this.ready = true
        this.pushToSignup(uid)
      })
    },

    setGoogleUserDataToFirestore(user: firebase.User) {
      const uid = user.uid
      const userData: User = {
        uid: uid,
        createdAt: new Date(),
        thumbnail: user.photoURL ? user.photoURL : '',
        name: user.displayName ? user.displayName : '',
        email: user.email ? user.email : '',
        birthYear: 1990,
        birthMonth: 1,
        birthDay:1
      }
      DB.collection('users')
      .doc(user.uid)
      .set(userData)
      .then(() => {
        this.ready = true
        this.pushToSignup(uid)
      })
    },

    updateUserImage(user: firebase.User, additionalUserInfo: firebase.auth.AdditionalUserInfo) {
      DB.collection('users')
      .doc(user.uid)
      .update({
        thumbnail: (additionalUserInfo as any).profile.profile_image_url_https ? (additionalUserInfo as any).profile.profile_image_url_https.replace('_normal', '') : '',
      })
    },
    
    pushToUserProduct(v: string) {
      this.ready = true
      this.$router.push({ name: 'UsersProduct' })
    },

    pushToSignup(v: string) {
      if(v){
        this.$router.push({ name: 'Signup', params: { id: v.toString() }})
      }
      if(!v){
        this.$router.push({ name: 'Signup' })
      }
    },
  }
})
