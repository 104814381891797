



















































































































import Vue from 'vue'
import { DB } from '@/firebase/db'

export default Vue.extend({
  name: 'ProductList',

  data: () => ({
    categoryName: '',
    videos: {},
    products: [] as any,
    brands: [] as any,
    sortedBrands: '',
    tabColor: '#19C0C9',
    tabSliderColor: '#19C0C9',
    lastVisible: {} as firebase.firestore.DocumentData,
    params: 'times',
    order: 'desc',
    isFetch: true,
    tabs: [
      {
        name: '人気順',
        params: 'times',
        order: 'desc'
      },
      {
        name: '安い順',
        params: 'price',
        order: 'asc'
      },
      {
        name: '高い順',
        params: 'price',
        order: 'desc'
      }
    ]
  }),

  created: function() {
    this.fetchCategories()
    this.fetchProducts('times', 'desc')
    window.scrollTo({
      top: 0
    })
  },
  
   watch: {
    sortedBrands: function() {
      this.fetchProducts(this.params, this.order)
    },
  },

  methods: {
    fetchCategories (): void {
      DB.collection('categories')
      .where('id', '==', this.$route.params.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.categoryName = doc.data().categoryName
        })
      })
    },

    fetchProducts (params: string, order: string): any {
      this.products = []
      this.isFetch = false
      this.params = params
      this.order = order
      const products = [] as any
      const brands = [] as any
      const option = order == 'desc' ? 'desc' : 'asc'
      DB.collection('products')
      .where('categoryId', '==', this.$route.params.id)
      .orderBy(params, option)
      .get()
      .then((querySnapshot) => {
        this.lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
        querySnapshot.forEach((doc) => {
          if (doc.data().times != 0 && doc.data().price != 0) {
            products.push(doc.data())
            brands.push(doc.data().brand)
          }
        })
      })
      .then(() => {
        if (this.sortedBrands == ''){
          this.products = products
        }
        if (this.sortedBrands != ''){
          this.products = products.filter((doc: any) => {
            return (doc.brand === this.sortedBrands)
          })
        }
        this.brands = Array.from(new Set(brands)).sort()
        this.isFetch = true
      })
    },

    initialize() {
      this.sortedBrands = ''
    },

    routerPush(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    }
  }
})
