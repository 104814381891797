
















































































































































































































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import axios from 'axios'
import SuggestVideoList from '@/components/SuggestVideoList.vue'
import YoutubePlayer from '@/components/YoutubePlayer.vue'

export default Vue.extend({
  name: 'VideoProduct',

  components: {
    SuggestVideoList,
    YoutubePlayer
  },

  data: () => ({
    video: {} as any,
    currentVideo: {} as any,
    videoUniques: [] as any,
    products: [] as any,
    comments: [] as any,
    currentProduct: {} as any,
    time: "0:00",
    videoModalId: {} as any,
    isFixed: false,
    isDisplay: true,
    isReady: true,
    isShow: {} as any,
    currentProductName: ''
  }),

  created: function() {
    this.fetchVideoData(this.$route.params.id)
    this.fetchProductData(this.$route.params.id)
    window.scrollTo({
      top: 0
    })
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  watch: {
    $route (to, from) {
      this.initialize()
      this.fetchVideoData(to.params.id)
      this.fetchProductData(to.params.id)
      window.scrollTo({
        top: 0
      })
    }
  },

  methods: {
    initialize(){
      this.video = {}
      this.currentVideo = {}
      this.videoUniques = []
      this.products = []
      this.videoModalId = {}
    },

    handleScroll() {
      if(window.scrollY>70){
        this.isFixed = true
        this.isDisplay = false
      } else {
        this.isFixed = false
        this.isDisplay = true
      }
    },
    
    fetchVideoData(id: string) {
      DB.collection('videoUnique')
      .doc(id)
      .get()
      .then((doc) => {
        this.video = doc.data()
        this.currentVideo = doc.data()
      })
      .then(() => {
        this.fetchVideoUnique(this.video.personalColorSeason, this.video.id)
      })
    },
    
    fetchProductData(id: string): any {
      DB.collection('videos')
      .where('videoId','==', id)
      .orderBy('sortId', 'asc')
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          this.products.push(doc.data())
        })
      })
      .then(() => {
        this.currentProduct = this.products[0]
      })
    },

    fetchComments (product: any): void {
      const videoArray = [] as any
      const commentArray = [] as any
      const arr = [] as any
      DB.collection('videos')
      .where('productId', '==', product.productId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          videoArray.push(doc.data())
          commentArray.push(doc.data())
        })
      })
      .then(() => {
        const sortCommentLength = commentArray.sort((a: any, b: any) => {
            if (a.comment.length < b.comment.length) {
              return -1
            } else {
              return 1
            }
          })
        this.comments = sortCommentLength.sort((a: any, b: any) => {
          if ((b.videoId === this.currentVideo.id)) {
            return 1
          } else {
            return -1
          }
        })
      })
      .then(() => {
        this.toggleShowPost(this.currentVideo.id, this.comments[0].id)
      }) 
      
    },

    changeVideoAtProductList(product: any, video: any) {
      const currentVideo = video
      this.currentProductName = product.productName
      this.isReady = false
      this.fetchComments(product)
      this.time = product.time
      this.currentProduct = product
      this.products.forEach((e: any) => {
        if (e.id == product.id) {
          e['class'] = 'highlight'
        }
        if (e.id != product.id) {
          e['class'] = ''
        }
      })
      
      setTimeout(() => {
        this.video = currentVideo
        this.isReady = true
      }, 10)
    },

    changeVideo(video: any) {
      const videoId = video.videoId
      this.toggleShowPost(videoId, video.id)
      DB.collection('videoUnique')
      .doc(videoId)
      .get()
      .then((doc) => {
        this.video = doc.data()
        this.time = video.time
      })
    },

    publishedAt(datetime: Date) {
      const from = new Date(datetime);
      const diff = new Date().getTime() - from.getTime();
      const elapsed = new Date(diff);

      if (elapsed.getUTCFullYear() - 1970) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
      } else if (elapsed.getUTCMonth()) {
        return elapsed.getUTCMonth() + 'ヶ月前';
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + '日前';
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + '時間前';
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + '分前';
      } else {
        return elapsed.getUTCSeconds() + 'たった今';
      }
    },

    convertTime(time: string) {
      const timeArray = time.split(':')
      return Number(timeArray[0]) * 60 + Number(timeArray[1])
    },

    truncate(str: string, len: number){
      return str.length <= len ? str: (str.substr(0, len)+"...");
    },

    fetchVideoUnique (personalColorSeason: string, videoId: string): void {
      const videoUniqueArray = [] as any
      DB.collection('videoUnique')
      .orderBy('publishedAt', 'desc')
      .limit(50)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if(videoId != doc.id){
            videoUniqueArray.push(doc.data())
          }
        })
        this.videoUniques = this.shuffleFisherYates(videoUniqueArray)
      })
    },

    shuffleFisherYates(arr: any[]): any[] {
      const newArray = arr
      for(let i = newArray.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * (i + 1));
        const tmp = newArray[i];
        newArray[i] = newArray[j];
        newArray[j] = tmp;
      }
      return newArray
    },

    toggleShowPost(videoId: string, id: number){
      this.$ga.event('pushReview', 'push')
      this.$set(this.isShow, id, true)
      this.comments.forEach((e: any) => {
        if (e.videoId == videoId) {
          e['class'] = 'highlight'
        }
        if (e.videoId != videoId) {
          e['class'] = ''
        }
      })
    },

    ready(youtube: string) {
      const youtubePlayer: any = this.$refs[youtube]
      youtubePlayer[0].player.mute()
      youtubePlayer[0].player.playVideo()
    },

    changeParams() {
      this.comments = []
    },

    ended() {
      // console.log('ended')
    },

    routerPush(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    openWindow (url: string) {
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    pushToChannelPage(v: number): void {
      console.log('')
      // this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToProductPage(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToVideoPage(v: number): void {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    }
  }
})
