


























































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import store from '@/store'
import { DB } from '@/firebase/db'
import firebase, { functions } from 'firebase'
import SearchProduct from '@/components/SearchProduct.vue'


export default Vue.extend({
  name: 'Product',

  components: {
    SearchProduct
  },

  data: () => ({
    product: {} as any,
    sameBrandProduct: [] as any,
    sameCategoryProduct: [] as any,
    otherModel: [] as any,
    videos: [] as any,
    comments: [] as any,
    channels: [] as any,
    playerId: [] as any,
    isShow: {} as any,
    uid: '',
    onAuthState: false,
    existsCosme: false,
    addToCosmePouchButtonLabel: 'コスメポーチに追加',
    isFetch: false,
    scrollY: 0,
    tabColor: '#19C0C9',
    tabSliderColor: '#19C0C9',
    currentSort: 'popular',
    currentModelNumber: '',
    currentVideos: [] as any,
    isMoreReview: false,
    isIcon: true,
    isAnonymous: false,
    tabs: [
      {
        name: '商品TOP',
        value: 'Product'
      },
      {
        name: '色/画像',
        value: 'Swatch'
      }
    ],
    sortItems: [
        {
          title: '人気順',
          value: 'popular',
          color: '#1976D2'
        },
        {
          title: '名前順',
          value: 'name',
          color: '#333333'
        }
      ],
  }),

  computed: {
    breadcrumbs: function() {
      const breadcrumbs = [
        {
          text: 'TOP',
          disabled: false,
          href: '/',
        },
        {
          text: this.product.brand,
          disabled: false,
          href: `/brandproduct/${this.product.brandId}`,
        },
        {
          text: this.product.name,
          disabled: true
        },
      ]
      return breadcrumbs
    }
  },

  created: function() {
    this.fetchProduct(this.$route.params.id)
    this.onAuthStateChanged(this.$route.params.id)
    window.scrollTo({
      top: 0
    })
  },

  watch: {
    currentSort: function(newVal, oldVal) {
      if(newVal == 'popular'){
        this.otherModel.sort((a: any, b: any) => {
          if (a.index < b.index) {
            return -1
          } else {
            return 1
          }
       })
      }
      if(newVal == 'name'){
        this.otherModel.sort((a: any, b: any) => {
          if (a.product.modelNumber < b.product.modelNumber) {
            return -1
          } else {
            return 1
          }
       })
      }
    }
  },

  mounted() {
    // window.addEventListener('scroll', this.handleScroll)
  },

  updated() {
    this.playerId.forEach((v: any, i: number) => {
      const dom: any = this.$refs[`player_${v.id}`]
      const rect = dom[0].getBoundingClientRect()
      this.playerId[i].top = rect.top
      this.playerId[i].bottom = rect.bottom
    })
  },

  methods: {
    // handleScroll() {
    //   this.scrollY = window.scrollY
    //   this.playerId.forEach((e: any, i: number) => {
    //     const gapY = this.playerId[i].top - this.scrollY
    //     const youtubePlayer: any = this.$refs[e.id]
    //     const start = youtubePlayer[0].playerVars.start
    //     if(gapY < 400 && gapY > -100){
    //       youtubePlayer[0].player.mute()
    //       youtubePlayer[0].player.playVideo()
    //       if(!e.isPlay){
    //         this.setPlayCountAtCramel(e.id)
    //         this.$ga.event('playVideoScroll', 'scroll')
    //       }
    //       e.isPlay = true
    //     } else {
    //       youtubePlayer[0].player.pauseVideo()
    //     }
    //   });
    // },

    fetchVideos (productName: string): void {
      const videoArray = [] as any
      const commentAraay = [] as any
      const arr = [] as any
      DB.collection('videos')
      .where('productName', '==', productName)
      .orderBy('viewCount', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          videoArray.push(doc.data())
          if(doc.data().comment != "") {
            commentAraay.push(doc.data())
          }
        })
        this.videos = videoArray
        this.currentVideos = videoArray
        // this.comments = commentAraay.sort((a: any, b: any) => {
        //     if (a.modelNumber < b.modelNumber) {
        //       return -1
        //     } else {
        //       return 1
        //     }
        //   })
      })
      .then(() => {
        this.videos.forEach((e: any) => {
          arr.push(e.channelId)
          this.playerId.push(
            {
              id: e.videoId,
              isPlay: false
            }
          )
        })
      })
    },

    fetchProduct (id: string): void {
      DB.collection('products')
      .where('id', '==', id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.product = doc.data()
        })
      })
      .then(() => {
         document.title = `【${this.product.brand}/${this.product.name}/${this.product.modelNumber}】人気YouTuberの動画レビューまとめ｜Cramel（クラメル）`
      })
      .then(() => {
        this.fetchVideos(this.product.name)
        this.fetchOtherModel(this.product.name)
        this.fetchSameBrandProduct(this.product.brand, this.product.categoryId)
        this.fetchSameCategoryProduct(this.product.brand, this.product.categoryId)
        // this.fetchBrandId()
      })
      .then(() => {
        this.isFetch = true
      })
    },

    fetchOtherModel (name: string): void {
      const arr = [] as any
      let index = 0
      DB.collection('products')
      .where('name', '==', name)
      .orderBy('times', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.otherModel.push(
            {
              product: doc.data(),
              index: index
            }
          )
          index++
        })
      })
    },

    fetchSameBrandProduct(brand: string, categoryId: number): void {
      const sameBrandProductArray = [] as any
      const array = [] as any
      DB.collection('products')
      .where('brand', '==', brand)
      .where('categoryId', '==', categoryId)
      .orderBy('times', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if(doc.id != this.$route.params.id) {
            array.push(doc.data())
          }
        })
      })
      .then(() => {
        for(let i = 0; i < 3; i++) {
          if (array[i]) {
            sameBrandProductArray.push(array[i])
          }
        }
        this.sameBrandProduct = sameBrandProductArray
      })
    },

    fetchSameCategoryProduct(brand: string, categoryId: number): void {
      const sameCategoryProductArray = [] as any
      const array = [] as any
      DB.collection('products')
      .where('categoryId', '==', categoryId)
      .orderBy('times', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if(doc.id != this.$route.params.id && doc.data().brand != brand) {
            array.push(doc.data())
          }
        })
      })
      .then(() => {
        for(let i = 0; i < 3; i++) {
          if (array[i]) {
            sameCategoryProductArray.push(array[i])
          }
        }
        this.sameCategoryProduct = sameCategoryProductArray
      })
    },

    fetchBrandId () {
      DB.collection('brands')
      .where('brandName', '==', this.product.brand)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            console.log(doc.id)
        });
      })
    },

    openWindow (url: string) {
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    openWindowAmazon (url: string) {
      this.$ga.event('amazonAffiliatelink', 'push')
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    addToCosmePouch() {
      if(this.uid && !this.isAnonymous) {
        this.$ga.event('toCosmePouch', 'push')
        DB.collection('users')
        .doc(this.uid)
        .collection('cosmePouch')
        .doc(this.product.id)
        .set(this.product)
        .then(() => {
          this.existsCosme = true
          this.addToCosmePouchButtonLabel = 'コスメポーチに追加済み'
        })
      } else {
        this.$ga.event('toCosmePouchAnonymous', 'push')
        this.pushToSignin()
      }
    },

    onAuthStateChanged(productId: string) {
      firebase.auth().onAuthStateChanged( user => {
        if (user) {
          this.uid = user.uid
          this.isAnonymous = user.isAnonymous
          this.checkIfProductDataExistsInUsersSubCollection(user.uid, productId)
        } else {
          this.addToCosmePouchButtonLabel = 'コスメポーチに追加'
        }
      })
    },

    checkIfProductDataExistsInUsersSubCollection (uid: string, productId: string): void {
      DB.collection('users')
      .doc(uid)
      .collection('cosmePouch')
      .doc(String(productId))
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.existsCosme = true
          this.addToCosmePouchButtonLabel = 'コスメポーチに入っています'
        } else {
          this.existsCosme = false
          this.addToCosmePouchButtonLabel = 'コスメポーチに追加'
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    sortVideos(modelNumber: string): any {
      let result: any
      if(modelNumber == ''){
        result = this.videos.filter((element: any, index: number, self: any) =>
          self.findIndex((e: any) =>
            e.channelId === element.channelId
          ) === index
        )
      }
      if(modelNumber != ''){
        result = this.videos.filter((element: any, index: number, self: any) =>
          self.findIndex((e: any) =>
            e.channelId === element.channelId &&
            e.modelNumber === modelNumber
          ) === index
        )
      }
      if (this.isMoreReview) {
        return result
      }
      if (!this.isMoreReview) {
        return result.slice(0,6)
      }
    },

    sortIcons(modelNumber: string): any {
      let result: any
      if(modelNumber == ''){
        result = this.videos.filter((element: any, index: number, self: any) =>
          self.findIndex((e: any) =>
            e.channelId === element.channelId
          ) === index
        )
      }
      if(modelNumber != ''){
        result = this.videos.filter((element: any, index: number, self: any) =>
          self.findIndex((e: any) =>
            e.channelId === element.channelId &&
            e.modelNumber === modelNumber
          ) === index
        )
      }
      return result
    },

    sortVideoCards(modelNumber: string): any {
      let result: any
      if(modelNumber == ''){
        result = this.videos.sort((a: any, b: any) => {
          if (a.viewCount > b.viewCount) {
            return -1
          } else {
            return 1
          }
        })
      }
      if(modelNumber != ''){
        result = this.videos.filter((element: any, index: number, self: any) =>
          element.modelNumber === modelNumber
        )
        .sort((a: any, b: any) => {
          if (a.viewCount > b.viewCount) {
            return -1
          } else {
            return 1
          }
        })
      }
      this.currentVideos = result
      return result
    },

    scrollToCard(id: string) {
      this.isMoreReview = true
      this.sortVideoCards(this.currentModelNumber)
      this.currentVideos.forEach((e: any) => {
        if (e.channelId == id) {
          e['color'] = '#E2526E'
        }
        if (e.channelId != id) {
          e['color'] = ''
        }
      })
      setTimeout(() => {
        const element: any = document.getElementById(id)
        element.scrollIntoView({
          behavior: 'smooth'
        })
      }, 100)

    
      // const position = element.getBoundingClientRect().top
      // window.scrollTo({
      //   top: position,
      //   behavior: 'smooth'
      // })
    },


    pushToSignin() {
      this.$router.push({ name: 'Signin' })
    },

    pushToYoutubersProduct(v: number) {
      console.log('')
      this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToBrandProductPage(v: number): void {
      this.$router.push({ name: 'BrandProduct', params: { id: v.toString() }})
    },

    playerVars(time: string, id: string) {
      const convertedTime = this.convertTime(time)
      const playerVars = {
        playsinline: 1,
        autoplay: 1,
        start: convertedTime,
        end: convertedTime+20
      }
      return playerVars
    },

    convertTime(time: string) {
      const timeArray = time.split(':')
      return Number(timeArray[0]) * 60 + Number(timeArray[1])
    },

    truncate(str: string, len: number){
        return str.length <= len ? str: (str.substr(0, len)+"...");
    },

    ready(id: string) {
      // let count = 0
      const youtubePlayer: any = this.$refs[id]
      // youtubePlayer[0].player.mute()
      // youtubePlayer[0].player.playVideo()
      // const timer = setInterval(() => {
      //   count+=1
      //   if(count<1000 && this.$route.path.includes('product')){
      //     console.log(count)
      //     console.log(this.$route.path.includes('product'))
      //     youtubePlayer.player.seekTo(youtubePlayer.player.playerVars.start)
      //     youtubePlayer.player.mute()
      //     youtubePlayer.player.playVideo()
      //   } else {
      //     console.log('stop')
      //     clearInterval(timer)
      //   }
      // }, 20000)
    },

    ended() {
      console.log('ended')
    },

    changeParams (id: string) {
      this.channels = []
      this.otherModel = []
      this.existsCosme = false
      this.sameBrandProduct = []
      this.sameCategoryProduct = []
      this.isMoreReview = false
      this.playerId = []
      this.onAuthStateChanged(this.$route.params.id)
      this.fetchProduct(id)
      window.scrollTo({
        top: 0
      })
    },

    changeSort(value: string){
      this.currentSort = value
      this.sortItems.map((e: any) => {
        if(e.value == value) {
          e.color = '#1976D2'
        } else {
          e.color = '#333333'
        }
      })
    },

    changeProductImage (product: any){
      this.isIcon = false
      this.product = product
      this.currentModelNumber = product.modelNumber
      this.isMoreReview = false
      this.onAuthStateChanged(product.id)
      this.sortVideoCards(product.modelNumber)
      setTimeout(() => {
        this.isIcon = true
      },100)
    },

    toggleShowPost(key: number){
      this.$set(this.isShow, key, !this.isShow[key])
      // console.log(key)
    },


    setPlayCountAtCramel(videoid: string) {
      const increment = firebase.firestore.FieldValue.increment(1)
      const docRefProducts = DB.collection('products').doc(String(this.product.id))
      const docRefVideoUnique = DB.collection('videoUnique').doc(videoid)
      docRefProducts
      .set({
        viwesAtCramel: increment
      },
      { 
        merge: true
      })

      docRefVideoUnique
      .set({
        viwesAtCramel: increment
      },
      { 
        merge: true
      })
    },

    pushToIndividualProductVideo(id: string, time: string, productId: string): void {
      const params = `${id}&${time}&${productId}`
      this.$router.push({ name: 'IndividualProductVideo', params: { id: params.toString() }})
    },
    
    routerPush(view: string) {
      if( view != 'Product'){
        this.$router.push({ name: view, params: { id: this.$route.params.id }})
      }
    }
  }
})
