














































import Vue from 'vue'


export default Vue.extend({
  name: 'SearchProduct',

  props: {
    handleProducts: [] as any
  },

  // data: () => ({
  // }),
  watch: {
    $route (to, from) {
      // console.log(to.params.id)
      this.$emit('parentMethod', to.params.id)
    }
  },

  methods: {
    pushProductPage(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    }
  }
})
