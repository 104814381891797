

























































import Vue from 'vue'
import store from '@/store'
import { DB } from '@/firebase/db'
import algoliasearch from 'algoliasearch'
import SearchProduct from '@/components/SearchProduct.vue'
import SearchChannel from '@/components/SearchChannel.vue'
import SearchVideo from '@/components/SearchVideo.vue'



const client = algoliasearch('C6Y4RWNGUY', '4053f90fa27a6deea5833a5fd9ca1279')
const indexProducts = client.initIndex('cramel_products')
const indexChennels = client.initIndex('cramel_channels')
const indexVideos = client.initIndex('cramel_videos')

export default Vue.extend({
  name: 'Search',

  components: {
    SearchProduct,
    SearchChannel,
    SearchVideo
  },

  data: () => ({
    searchWord: '',
    products: [] as any,
    channels: [] as any,
    videos: [] as any,
    index: 'cramel_products',
    placeholder: '例. 3CE チーク',
    searchCategory: [
      // {
      //   name: 'コスメ動画',
      //   categoryIndex: 'cramel_videos'
      // },
      {
        name: 'コスメ',
        categoryIndex: 'cramel_products'
      },
      // {
      //   name: 'YouTuber',
      //   categoryIndex: 'cramel_channels'
      // }
    ]
  }),

  created: function() {
    window.scrollTo({
      top: 0
    })
  },

  methods: {
    async onInput() {
      if(this.index == 'cramel_products') {
        const searchResult = await indexProducts.search(this.searchWord, { hitsPerPage: 100 })
        this.products = searchResult.hits
      }
      if(this.index == 'cramel_channels') {
        const searchResult = await indexChennels.search(this.searchWord, { hitsPerPage: 100 })
        this.channels = searchResult.hits
      }
      if(this.index == 'cramel_videos') {
        const searchResult = await indexVideos.search(this.searchWord, { hitsPerPage: 100 })
        this.videos = searchResult.hits
      }
    },

    chanegePlaceholder() {
      this.products = []
      this.channels = []
      if(this.searchWord){
        this.onInput()
      }

      if(this.index == 'cramel_products') {
        this.placeholder = '例. 3CE チーク'
      }
      if(this.index == 'cramel_channels') {
        this.placeholder = '例. ゆうこす'
      }
      if(this.index == 'cramel_videos') {
        this.placeholder = '例. ゆうこす リップ'
      }
    }
  }
})
