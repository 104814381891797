



















































































































import Vue from 'vue'
import { DB } from '@/firebase/db'

export default Vue.extend({
  name: 'BrandProduct',

  data: () => ({
    videos: {},
    products: [] as any,
    brands: [] as any,
    categoryNames: [] as any,
    categoryIds: [] as any,
    sortedCategory: '',
    tabColor: '#19C0C9',
    tabSliderColor: '#19C0C9',
    lastVisible: {} as firebase.firestore.DocumentData,
    params: 'times',
    order: 'desc',
    isFetch: true,
    tabs: [
      {
        name: '人気順',
        params: 'times',
        order: 'desc'
      },
      {
        name: '安い順',
        params: 'price',
        order: 'asc'
      },
      {
        name: '高い順',
        params: 'price',
        order: 'desc'
      }
    ]
  }),

  created: function() {
    this.fetchProducts('times', 'desc')
    window.scrollTo({
      top: 0
    })
  },
  
   watch: {
    sortedCategory: function() {
      this.sort(this.params, this.order, this.sortedCategory)
    }
  },

  methods: {

    fetchProducts (params: string, order: string): any {
      this.products = []
      this.isFetch = false
      this.params = params
      this.order = order
      const products = [] as any
      const categoryNames = [] as any
      const categoryIds = [] as any
      const option = order == 'desc' ? 'desc' : 'asc'
      DB.collection('products')
      .where('brandId', '==', this.$route.params.id)
      .orderBy(params, option)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().times != 0 && doc.data().price != 0) {
            products.push(doc.data())
            categoryNames.push(doc.data().categoryName)
            categoryIds.push(doc.data().categoryId)
          }
        })
      })
      .then(() => {
        this.products = products
        this.categoryNames = Array.from(new Set(categoryNames)).sort()
        this.categoryIds = Array.from(new Set(categoryIds)).sort()
        this.isFetch = true
      })
    },

    fetchSortedProducts (params: string, order: string, category: any): any {
      this.products = []
      this.isFetch = false
      this.params = params
      this.order = order
      const products = [] as any
      const brands = [] as any
      const option = order == 'desc' ? 'desc' : 'asc'
      DB.collection('products')
      .where('categoryName', '==', category)
      .where('brandId', '==', this.$route.params.id)
      .orderBy(params, option)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().times != 0 && doc.data().price != 0) {
            products.push(doc.data())
          }
        })
      })
      .then(() => {
        this.products = products
        this.isFetch = true
      })
    },

    sort(params: string, order: string, category: string){
      if (category == '') {
        this.fetchProducts(params, order)
      }
      if (category != '') {
        this.fetchSortedProducts(params, order, category)
      }
    },

    initialize() {
      this.sortedCategory = ''
    },

    routerPush(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    }
  }
})
