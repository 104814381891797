




























import Vue from 'vue'
import Header from './components/Header.vue'
// import Banner from './components/Banner.vue'
import { DB } from '@/firebase/db'
import { Auth } from '@/firebase/auth'

export default Vue.extend({
  name: 'App',

  components: {
    Header,
    // Banner
  },

  data: () => ({
    currentRoute: ""
  }),

  created: function(){
    this.existsLogOfPWALaunch()
    this.currentRoute = this.$route.path
  },

  watch: {
    $route (to, from) {
      //遷移先のpathを取得
      this.detectPath(to.path)
    }
  },

  methods: {
    
    detectPath(path: any) {
      //pathの上書き
      this.currentRoute = path
    },

    existsLogOfPWALaunch() {
      const cookie = this.getCookie()
      if (this.$route.query['mode'] == 'standalone') {
        const docRef = DB.collection('cookies').doc(cookie)
        docRef.get()
        .then((doc) => {
          // console.log(doc.exists)
          if (!doc.exists) {
            docRef
            .set({
              cookie: cookie,
              createdAt: new Date(),
            })
            .then(() => {
              this.$ga.event('launchPWA', 'launch')
            })
          }
          if (doc.exists) {
            docRef
            .set({
              updatedAt: new Date()
            },
            { 
              merge: true
            })
          }
        })
      }
    },

    getCookie(): string {
      const cookies = document.cookie
      // console.log(document.cookie)
      const cookiesArray = cookies.split(';')
      let cookie = ''
      cookiesArray.forEach((c: any) => {
        const cArray = c.split('=')
          if( cArray[0] == '_ga'){
            cookie = cArray[1]
          }
      })
      return cookie
    }
  }
});
