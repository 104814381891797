




































import Vue from 'vue'

export default Vue.extend({
  name: 'TermsOfService',

data: () => ({
      terms: `「Cramel（クラメル）」（以下「本サイト」といいます。）は、株式会社ランデブー（以下、総称して「当社」といいます。）が運営するウェブサイトです。本サイトの名称で提供するすべてのウェブサイト、ソーシャルメディア等を含む一切の製品及びサービス・コンテンツ等（以下、本サイトを含めて総称して「本サービス」といいます。）の利用者（以下「利用者」といいます。）は、本サービスの一部でも閲覧・利用することにより、本利用規約及びその内容に同意したものとみなします。
 
              本サイトに掲載している動画コンテンツ（以下「本コンテンツ」といいます。）は、ランデブー社が本サイトにおける動画品質を認めたYouTuber（以下「クリエイター」といいます。）の動画から、YouTube Data APIにより収集され、本サイト上で表示・再生されるものです。ご利用に際してはYouTubeの規約（https://www.youtube.com/t/terms）に同意してください。

              本利用規約とは別途に定める諸規定及び本サービスの各サービスのガイドライン等は、本利用規約の一部を構成します。本利用規約と当該規定等とで相違がある場合は、当該規定等が優先されます。`,
      items: [
        {
          title: '規約の適用',
          text: `本利用規約は、すべての利用者に適用され、また、すべての本サービスの利用に適用されます。本利用規約のいずれかの規定が法令に違反していると判断された場合又は無効若しくは実施できないと判断された場合も、当該規定以外の各規定は、いずれも引き続き有効とします。`
        },
        {
          title: '規約の変更',
          text: `本利用規約は、当社が必要と判断する場合、利用者に事前に通知することなく、いつでも本規約の全部又は一部を任意に変更できるものとします。また、当社は、本規約に付随する規約・特約を新たに定めることができるものとし、同規約・特約も本規約の一部を構成するものとします。本規約の変更・追加は、当社が、本サービスを提供するウェブサイトにこれを掲載した時点からその効力を発するものとします。`
        },
        {
          title: '登録',
          text: `本規約においてクラメル登録者（以下「メンバー」とは、当社の指定する手続きに基づき、本規約を承諾のうえ当社所定の方法にて本サービスへの登録を申し込み、当社がこれを承認した者とします。
                ①当社が、メンバーとして承認することを不適切と判断した場合、登録を認めない場合があります。また承認後であっても承認を取り消し、登録の抹消を行う場合があります。
                ②すでに登録されているメンバーが、重複してメンバーの登録を申し込むことは禁止します。
                ③メンバーとして提供される権利又は義務を第三者に譲渡したり、貸与したり、第三者と共有することは禁止します。`
        },
        {
          title: 'クラメル公式クリエイタープログラム',
          text: `①当社は、利用者が投稿した投稿コンテンツについて、当社が広告等により収益を得た場合において、当該利用者が当該収益の獲得に貢献があったと当社が判断した場合は、当社の裁量により、当該利用者に当該収益を分配することができるものとします（以下、当該収益の分配の仕組みを「クラメル公式クリエイタープログラム」といいます）。
                ②クラメル公式クリエイタープログラムへの参加を希望する利用者は、当社所定の手続により申し込むものとします。当社が当該利用者の申込みを承諾した利用者（以下、当該利用者の申込みを承諾した利用者を『公式クリエイター』といいます）は、クラメル公式クリエイタープログラムに参加することができるものとします。
                ③クラメル公式クリエイタープログラムによる収益の分配は、当社が当月のクラメル公式クリエイタープログラムの対象とクラメル公式クリエイタープログラムに参加している各公式クリエイターに対する翌月末日までの分配により行うものとします。但し、当該分配の有無、金額その他一切の内容については、当社の裁量により決定するものとし、公式クリエイターは一切異議を述べないものとします。なお、分配に要する振込手数料その他一切の手数料は、各公式クリエイターの負担とします。
                ④公式クリエイターは、当社に対し、収益の分配のために必要な当社が要求する情報を正確に提供しなければならず、当該情報を提供しない場合には分配が行われないことを承諾するものとします。公式クリエイターは、当該情報に変更があった場合には、当社に対し直ちに報告しなければなりません。公式クリエイターが当社に対し当該情報を正確に提供しない場合又は当該情報に変更があったにもかかわらず報告を怠った場合であって、当社が収益を分配できないときは、当社が公式クリエイターに対し収益の分配を通知してから6ヶ月を経過した時点で、公式クリエイターは収益の分配を請求する権利を失うものとします。
                ⑤クラメル公式クリエイタープログラムに関する事項について、公式クリエイターはクラメル公式クリエイタープログラムによる収益の分配の有無、内容その他一切の事項を他の公式クリエイターその他第三者に提供、開示又は漏洩しないものとします。
                ⑥クラメル公式クリエイタープログラムの内容の変更、中断及び終了並びにクラメル公式クリエイタープログラムへの参加の停止及び取消しその他クラメル公式クリエイタープログラムに関する一切の事項は当社の裁量により決定するものとし、公式クリエイターは一切異議を述べないものとします。
                ⑦クラメル公式クリエイタープログラムに関して公式クリエイターに損害が生じた場合であっても、当社は一切の責任を負いません。`
        },
        {
          title: 'コンテンツの取り扱い',
          text: `①本規約において、「コンテンツ」とは、文章、音楽、画像、動画、その他の情報のことをいい、「本コンテンツ」とは、本サイトが他サービスからライセンスに則って収集したコンテンツのことをいい、「投稿コンテンツ」とは、利用者自身が本サービスに投稿、送信、アップロードするなどして当社の管理するサーバに保存させたコンテンツのことをいい、「公開投稿コンテンツ」とは、本サービスにおいて、他の利用者一般による閲覧またはアクセスが可能な投稿コンテンツのことをいいます。
                ②利用者は、公開投稿コンテンツを含むすべての投稿コンテンツに対して有する権利を従前どおり保持し、当社がかかる権利を取得することはありません。ただし、利用者は、本サービスの利用により、当社に対し、公開投稿コンテンツを利用する権利（営利目的の有無を問わず、複製、上演、演奏、上映、公衆送信、公衆伝達、口述、展示、頒布、譲渡、貸与、翻訳、翻案、（当社が事業目的上必要とみなす範囲で）改変する権利を含みます。また、これらを第三者に再許諾する権利を含みます。）を無償かつ無期限に、許諾したこととなり、当社はかかる利用権を取得します。この場合、利用者は、公開投稿コンテンツに関する著作者人格権を当社または当社の指定する第三者による上記範囲での利用に対して行使しないことにも同意したこととなります。
                ③利用者は、本コンテンツを、本サービスが当然に予定している利用態様を超えて利用（複製、送信、転載、改変などの行為を含みます。）してはなりません。ただし、権利者から事前に承諾を受けた場合はこの限りではありません。
                ④当社は、法令または本規約の遵守状況などを確認する必要がある場合、投稿コンテンツの内容を確認することができます。ただし、当社はかかる確認を行なう義務を負うものではありません。
                ⑤当社は、利用者が投稿コンテンツなどに関し法令もしくは本規約に違反しまたは違反するおそれのあると当社が認めた場合、その他業務上の必要性がある場合には、あらかじめ利用者に通知することなく、当社の管理するサーバから投稿コンテンツを削除するなどの方法により、本サービスまたは投稿コンテンツを利用できないようにすることができます。`
        },
        {
          title: '知的財産権',
          text: `本コンテンツ及び広告主等が発信する情報・コンテンツも含め、本サービスで提供される本コンテンツに関する権利は、本コンテンツを提供・発信した者又はその他原権利者が有し、本コンテンツ以外の本サービスに関する権利は当社が有しており、各権利は、著作権、商標権、特許権又は他の知的財産権及び法律によって保護されています。当社は、本規約に定める場合を除き、また本サービスについて、あらかじめ当社より書面による承諾を得た場合を除いて、複製、編集、改変、解析、公開、放送、展示、頒布、譲渡、貸与、翻訳、翻案、送信、転載、記録、再許諾、権利の登録、出願等、当社又は第三者の権利を侵害する行為を禁止します。`
        },
        {
          title: 'プライバシーについて',
          text: `当社は、本サービスにおいて、以下のようなアクセスデータを取得しています。データの収集には、Cookie及びこれに類する技術を使用しています。利用者がCookieの受け取りを拒否する場合は、一部機能に制約が生じることに同意することとします。また利用者は、本サービスによる第三者企業の広告配信サーバーの利用によりアクセスデータが第三者企業のサーバーに蓄積、管理されることに同意することとします。

                利用者の端末情報（ＩＰアドレス、オペレーティングシステムやブラウザーの種類、回線キャリア等）
                利用者の行動履歴（アクセスした時刻、ＵＲＬ、訪問回数等）
                当社は、本サービスにおいて、以下のようなアクセスデータを取得しています。データの収集には、Cookie及びこれに類する技術を使用しています。利用者がCookieの受け取りを拒否する場合は、一部機能に制約が生じることに同意することとします。また利用者は、本サービスによる第三者企業の広告配信サーバーの利用によりアクセスデータが第三者企業のサーバーに蓄積、管理されることに同意することとします。

                本サービス改善等のための分析や利用状況の把握
                利用者に最適と推定されるコンテンツの配信
                行動ターゲティング広告等の広告配信
                広告の配信状況の把握、広告効果の測定
                詳細は、「プライバシーポリシー」 をご覧ください。`
        },
        {
          title: '利用者の責任',
          text: `① 利用者は、利用者自身の自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為及びその結果、並びに損害について一切の責任を負うことに同意するものとします。
                ②本サービスを経由して、外部のWEBサイト、SNS、ブログ等のサービスを利用する場合は、本サービスと当該サービスが別のサービスであることを認識し、当該サービス提供元の利用規約が適用されることに同意するものとします。当該サービス利用にあたって発生するトラブルや損害（間接的であると直接的であるとを問いません）について、当社は関与せず、一切の責任を負わないものとします。`
        },
        {
          title: '利用料',
          text: `本サービスの利用料は原則無料ですが、利用者は、本サービスの利用に必要となる機器、ソフトウェア、通信・アクセス環境（携帯電話会社との間で必要な契約を含みます。）について、利用者自身の責任と費用において適切に準備、操作することとします。また、本サービスの利用には通信料がかかることがあります。利用者がパケットサービスをご利用の場合には送受信料がかかります。本サービスの利用に伴う通信料など全ての費用は、利用者の負担とします。`
        },
        {
          title: '広告等',
          text: `本サービス上で表示・実施される広告・販売促進等は、その主体となる広告主又は業者の責任で表示・実施されるものであり、利用者は、当該広告主又は業者との取り引き等を行う場合、利用者自身の責任と判断により行うこととします。当社及び配信者は、当該広告・販売促進等及び取り引き等に関連して生じたいかなる費用・損害等に関しても責任を負いません。`
        },
        {
          title: '本サービスへのリンク',
          text: `① 本サービスへのリンクは、営利を目的とせず、本サービスへのリンクである旨を明記し、フレームなしで行うなど一定の条件を満たしている限り、原則として自由です。ただし、本サービスの趣旨に合わない場合や情報発信源を誤認させるような形でリンクを張るなど、当社又は配信者の事業等を害する恐れがある場合は、リンク自体をお断りすることがあります。なお、編集・更新の都合により、本サービス内の各ページは予告なく変更・削除されることがあります。リンクを設定いただいても、短期間でリンク切れとなる可能性もありますので、あらかじめご了承ください。
                  ② いわゆるフレームリンク、直リンク、エンベッドなど、本サービスの内容を他サイトのコンテンツと組み合わせて表示するリンクや、本サービスの内容が単独で表示される場合であっても、リンク先のホームページの一部として表示される形のリンク、その他、一般に当社又は配信者の信用にかかわるようなリンクの仕方は、営利・非営利を問わず禁止します。`
        },
        {
          title: '本サービスの修正・廃止・中断',
          text: `当社は事前の告知なく、本サービスの中断・停止、修正、廃止・終了等（以下、総称して「中断等」といいます）をすることができます。当該中断等については、本サービスに関連する設備の保守が必要な場合、本サービスに関連する設備の障害があった場合、当社が必要と判断した場合、天災地変、戦争、内乱、暴動、疫病、法令の制定改廃・運用の変更、配信等の規制に関する基準の変更、公権力による命令処分、ストライキ、通信回線の障害その他の不可抗力又はやむを得ない事情が発生した場合――等の事情がある場合に行われますが、当該事由に限るものではありません。また、配信者は、自己の本コンテンツについて、自己の裁量により修正及び配信の中断・停止・終了等をすることができます。`
        },
        {
          title: '禁止事項',
          text: `① 当社、配信者又は第三者に損害を与える行為
                  ② 当社、配信者又は第三者の財産、名誉・信用・肖像・パブリシティー・プライバシー等に係る権利、著作権・特許権・商標権等の知的財産権、又はその他の権利・利益を侵害・毀損する行為
                  ③ 法令、公序良俗又は第三者との契約に反する行為、
                  ④ 本サービスを不正、営利、広告・宣言、宗教・政治活動を目的として利用する行為、又はその準備を目的とした行為
                  ⑤ データマイニング、ロボット等によるデータ収集・抽出ツールを使用する行為、コンピューターウィルス等有害なプログラムを使用若しくは提供する行為、又はコンピューターウィルスに感染したデータを配布する行為
                  ⑥ 当社、配信者又は本サービスの評価・名声・機能を失墜、低下、毀損させる行為（本サービスに関するサーバーに過度な負荷がかかる行為を含みます）
                  ⑦ 本サービスの運営又は第三者による本サービスの利用を妨害する行為
                  ⑧ 本サービスの趣旨に著しく外れた方法で本サービスを利用する行為
                  ⑨ 利用者間で住所、氏名、メールアドレスなどの個人情報を収集する行為
                  ⑩ 自己又は第三者のために本サービスにおける情報の投稿行為（クチコミを含みますがこれに限られません。）の正当な評価（クチコミのプレビュー数、いいね数、クリップ数を含みますがこれらに限られません。）を不正に増加させる行為
                  ⑪ その他、当社が合理的な理由に基づき不適切と判断する行為
                  `
        },
        {
          title: '免責事項',
          text: `本サービスは､利用者自身の判断と責任において､以下の項目を承諾した上で利用するものとし､ユーザーに生じたいかなる損失又は損害についても､当社は一切の責任を負わないものとします｡

                  ①当社は､本サービスの利用により取得できた情報又はサービスの内容に関する正確性､妥当性､適法性､有用性及びその他一切の事項について保証しないものとします｡
                  ②当社は、アクセス過多など、その他予期せぬ要因に基づく本サービスの表示速度の低下や障害等によって生じたいかなる損害について一切の責任を負わないものとします。
                  ③当社の判断により本サービスの利用停止等の処置を受けたユーザーは、当該処置の理由の開示を求めることができないものし、当該処置について争わないものとします。
                  ④前記各号記載事項の他、本サービスの利用にあたりユーザーに発生した一切の損害について、当社は原則として責任を負わないものとします。また、当社の責任に帰すべからざる事由から発生した損害、当社の予見の有無にかかわらず、特別の事情から生じた障害、逸失利益については責任を負わないものとします。
                  `
        },
        {
          title: '本コンテンツに対する責任の所在・削除',
          text: `当社は、本サービスにおけるコンテンツの内容、品質、正確性、信憑性、適法性（以下、他人の権利の非侵害を含みます）、最新性、有用性などについて、一切保証せず、一切責任を負わないものとします。
                  当社は、利用者が本サービスのコンテンツを利用することにより生じる損害について、一切責任を負いません。`
        },
        {
          title: '損害賠償の請求',
          text: `利用者が本利用規約への違反、違法・不当な本サービスの利用等を行って、又はその他利用者の責めに帰する事由によって当社、配信者又は第三者に損害を与えた場合、利用者は当該損害を賠償することとします。`
        },
        {
          title: '準拠法及び管轄裁判所',
          text: `本利用規約及び利用者・当社・配信者には日本法が適用されます。利用者と当社又は配信者との間で本利用規約及び本サービスに関する紛争が生じた場合、両者で誠意をもって協議をすることとしますが、それでも解決しない場合は、東京地方裁判所又は東京簡易裁判所をもって第一審の専属的合意管轄裁判所とします。`
        },
      ]
    }),

    methods: {
    routerPush(view: string): void {
      this.$router.push({ name: view })
    }
  }
})
