























































































































































































































































































































































































































































































import Vue from 'vue'
import CarouselImage from '@/components/CarouselImage.vue'
import store from '@/store'
import { DB } from '@/firebase/db'
import firebase from 'firebase'

export default Vue.extend({
  name: 'Top',

  components: {
    CarouselImage
  },

  data: () => ({
    topBannerImage: require('@/assets/images/featureCosme.001.jpeg'),
    personalColormage: require('@/assets/images/personalColorImage.jpeg'),
    videos: [] as any,
    channels: [] as any,
    topChannels: [] as any,
    brands: [] as any,
    products: [] as any,
    videoModalId: {} as any,
    playerVars: {
      playsinline: 1,
      autoplay: 1,
      start: 0
    },
    feature: [
      // {
      //   tag: '韓国コスメ',
      //   image: require('@/assets/images/featureCosme.002.jpeg')
      // },
      {
        tag: 'オレンジメイク',
        image: require('@/assets/images/featureCosme.003.jpeg')
      },
      // {
      //   tag: 'デパコス',
      //   image: require('@/assets/images/featureCosme.004.jpeg')
      // },
      {
        tag: 'プチプラ',
        image: require('@/assets/images/featureCosme.005.jpeg')
      },
      {
        tag: 'ベスコス',
        image: require('@/assets/images/featureCosme.006.jpeg')
      },
      {
        tag: '敏感肌',
        image: require('@/assets/images/featureCosme.007.jpeg')
      }
    ],
    categories: [
      {
        label: 'スキンケア',
        value: 'skincare',
        image: require('@/assets/images/cosme/foundationPrimer.png')
      },
      {
        label: '日焼け止め',
        value: 'sunscreenFace',
        image: require('@/assets/images/cosme/sunscreenFace.png')
      },
      {
        label: 'コンシーラー',
        value: 'concealer',
        image: require('@/assets/images/cosme/concealer.png')
      },
      {
        label: 'ファンデ',
        value: 'foundation',
        image: require('@/assets/images/cosme/foundation.png')
      },
      {
        label: 'チーク',
        value: 'blush',
        image: require('@/assets/images/cosme/blush.png')
      },
      {
        label: 'アイシャドウ',
        value: 'eyeshadow',
        image: require('@/assets/images/cosme/eyeshadow.png')
      },
      {
        label: 'マスカラ',
        value: 'mascara',
        image: require('@/assets/images/cosme/mascara.png')
      },
      {
        label: 'アイライナー',
        value: 'eyeliner',
        image: require('@/assets/images/cosme/eyeliner.png')
      },
      {
        label: 'アイブロウ',
        value: 'eyebrow',
        image: require('@/assets/images/cosme/eyebrow.png')
      },
      {
        label: 'リップグロス',
        value: 'lipGloss',
        image: require('@/assets/images/cosme/lipGloss.png')
      },
      {
        label: 'リップ',
        value: 'lipstick',
        image: require('@/assets/images/cosme/lipstick.png')
      },
      {
        label: 'ブラシ',
        value: 'brushes',
        image: require('@/assets/images/cosme/brushes.png')
      },
      {
        label: 'マニキュア',
        value: 'nailPolish',
        image: require('@/assets/images/cosme/nailPolish.png')
      },
      {
        label: '美容グッズ',
        value: 'otherGoods',
        image: require('@/assets/images/cosme/eyelashCurler.png')
      }
    ]
  }),

  created: function() {
    // if(window.matchMedia('(display-mode: standalone)').matches){
    // }
    this.fetchProductData()
    this.fetchVideos()
    this.fetchBrandData()
    this.fetchChannelData()
    // window.scrollTo({
    //   top: 0
    // })
  },

  methods: {
    fetchVideos (): void {
      DB.collection('videoUnique')
      .orderBy('publishedAt', 'desc')
      .limit(6)
      .get()
      .then(querySnapShot => {
        querySnapShot.forEach(doc => {
          this.videos.push(doc.data())
        })
      })
    },

    fetchProductData(): void {
      const productArray = [] as any
      DB.collection('products')
      .orderBy('times', 'desc')
      .limit(9)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          this.products.push(doc.data())
        })
        // this.products = productArray
      })
    },

    fetchBrandData(): void {
      const productArray = [] as any
      DB.collection('brands')
      .orderBy('times', 'desc')
      .limit(100)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          this.brands.push(doc.data())
        })
        // this.products = productArray
      })
    },

    fetchChannelData(): void {
      const productArray = [] as any
      DB.collection('channels')
      .orderBy('subscriberCount', 'desc')
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          this.channels.push(doc.data())
        })
        // this.products = productArray
      })
      .then(() => {
        const topChannels = this.channels.slice(0, 6)
        topChannels.forEach((e: any) => {
          DB.collection('videoUnique')
          .where('channelId', '==', e.id)
          .orderBy('publishedAt', 'desc')
          .limit(1)
          .get()
          .then((querySnapShot) => {
            querySnapShot.forEach(doc =>{
              this.topChannels.push(doc.data())
            })
          })
        })
      })
    },

    publishedAt(datetime: Date) {
      const from = new Date(datetime);
      const diff = new Date().getTime() - from.getTime();
      const elapsed = new Date(diff);

      if (elapsed.getUTCFullYear() - 1970) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
      } else if (elapsed.getUTCMonth()) {
        return elapsed.getUTCMonth() + 'ヶ月前';
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + '日前';
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + '時間前';
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + '分前';
      } else {
        return elapsed.getUTCSeconds() + 'たった今';
      }
    },

    closeVideoModal (index: number, youtube: any) {
      const youtubePlayer: any = this.$refs[youtube]
      youtubePlayer[0].player.stopVideo()
      if (!this.videoModalId[index]) {
        this.$set(this.videoModalId, index, true)
      } else {
        this.videoModalId[index] = !this.videoModalId[index]
      }
    },

    pushToSortedChannelList(tag: string) {
      this.$router.push({
        name: 'SortedChannelList',
        params: {
          tag: tag
        }
      })
    },

    convertTime(time: string) {
      const timeArray = time.split(':')
      return Number(timeArray[0]) * 60 + Number(timeArray[1])
    },

    openVideoModal (time: string) {
      this.playerVars.start = this.convertTime(time)
      this.$ga.event('openVideoModalAtVideoList', 'push')
    },

    ready(youtube: string) {
      // console.log('ready')
      const youtubePlayer: any = this.$refs[youtube]
      youtubePlayer[0].player.mute()
      youtubePlayer[0].player.playVideo()
    },

    existsLogOfPWALaunch() {
      const cookie = this.getCookie()
      if (this.$route.query['mode'] == 'standalone') {
        const docRef = DB.collection('cookies').doc(cookie)
        docRef.get()
        .then((doc) => {
          // console.log(doc.exists)
          if (!doc.exists) {
            docRef
            .set({
              cookie: cookie,
              createdAt: new Date(),
            })
            .then(() => {
              this.$ga.event('launchPWA', 'launch')
            })
          }
          if (doc.exists) {
            docRef
            .set({
              updatedAt: new Date()
            },
            { 
              merge: true
            })
          }
        })
      }
    },

    getCookie(): string {
      const cookies = document.cookie
      // console.log(document.cookie)
      const cookiesArray = cookies.split(';')
      let cookie = ''
      cookiesArray.forEach((c: any) => {
        const cArray = c.split('=')
          if( cArray[0] == '_ga'){
            cookie = cArray[1]
          }
      })
      return cookie
    },
 
    openWindow (url: string) {
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    pushToPersonalcolorAnalysis() {
      this.$router.push({ name: 'PersonalColorAnalysis'})
    },

    pushToChannelPage(v: number): void {
      this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToProductPage(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToSortedProductPage(v: string): void {
      this.$router.push({ name: 'SortedProductList', params: { id: v }})
    },

    pushToFeatureProduct(v: string): void {
      this.$router.push({ name: 'FeatureProduct', params: { id: v }})
    },

    pushToVideoPage(v: number): void {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    },

    pushToBrandProductPage(v: number): void {
      this.$router.push({ name: 'BrandProduct', params: { id: v.toString() }})
    },

    pushToOtherPage(name: string): void {
      this.$router.push({ name: name })
    }
  }
})
