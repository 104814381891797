




















































import Vue from 'vue'

export default Vue.extend({
  name: 'Sitepolicy',

  data: () => ({
      panel: [0, 1],
      items: [
        // {
        //   title: '宣誓',
        //   text: `Cramelでは「クリエイターエコノミー」をテーマに「本当に質の高いコンテンツを発信しているクリエイターを支援したい」と考えており、まずは過去のコンテンツを資産化、フル活用することで稼げるクリエイターを一人でも増やし、より良いコンテンツを制作出来るように支援していきます。
        //         ※詳しい詳細に関しては「3. 公式クリエイターについて」をご参照ください`
        // },
        {
          title: 'サービス内のコンテンツ引用について',
          text: `当サービスに掲載しているYouTube動画コンテンツ・画像等は、YouTubeの利用規約を遵守しYouTubeからライセンス付与された上でYouTube Data APIにより収集され、当サイト上で表示・再生されるものです。
                  当サービス内のYouTubeコンテンツへのご不明点に関してはYouTubeの利用規約の閲覧をお願い致します。
                  また、当サイトに掲載されている動画で、他メディアへの埋め込みを制限したいクリエイターは、YouTube Studioのコンテンツマネージャより埋め込み設定の変更が可能です。詳細は<a href="https://support.google.com/youtube#topic=9257498" target=”_blank”>YouTubeヘルプ</a>よりご参照ください。`
        },
        // {
        //   title: 'サービス立ち上げの背景',
        //   text: `インターネットの大きな発展により個人が自由に表現・発信できるプラットフォームが数多く存在している中で、個人のクリエイターとフォロワーの関係は単なる繋がりではなく一つの経済圏となってきています。そして、これまで一消費者だったユーザーも続々と発信者になっておりクリエイター達が生み出す経済圏は日々大きくなっています。これらクリエイターエコノミーの基盤となる新規・既存クリエイターのサポートが当サービスの目的です。`
        // },
        {
          title: 'クリエイターの皆様へ',
          text: ` 過去動画を、動画以上の資産へ転換するのがクラメルの役割です。YouTube内ではあまりサジェストされづらい過去の動画を当サイト内の掲載コスメをもとに当サイト内でサジェストし再生回数増加を促します。現在は、フォロワー数や動画再生回数で評価されてしまっている実情がありますが、クラメルではコンテンツの質でその距離を縮められるような場にしていきたいと考えております。`
        },
        {
          title: '公式クリエイターについて',
          text: `当サービスではYouTube Data APIを利用して掲載しているコンテンツの他に、「公式クリエイター」のみ掲載できる下記の様な独自コンテンツの準備をしています。

                  ・公式クリエイターページを作成し、他プラットフォームへのリンクの設置
                  ・公式クリエイターご自身が管理する購買リンクやプロデュースブランドの販売リンク設置
                  ・公式クリエイター毎の特集や専用コンテンツの作成
                  
                  現在準備中のため、公式クリエイターへの申請については<a href="https://docs.google.com/forms/d/e/1FAIpQLScG_Ci2zP3Fj5adp5u8gF0RMK8oP0HkkNMLthAW3X5GAyT_1w/viewform?usp=sf_link" target=”_blank”>事前申請フォーム</a>をご用意しております。`
        },
        {
          title: '公式クリエイターのメリットについて',
          text: `・クラメル内にご自身の販売先やアフィリエイトリンクを設置し、その場合の手数料は当サービスでは一切かかりません。
                ・当サービス内で発生した収益は当サービスのシステム上で公正に集計しクリエイターに還元致します。
                  ・動画コンテンツの整理やクリエイター側に用意していただいた商品リンク等の設置もすべて当サービスの担当者が行います。
                  ・YouTubeをあまり視聴していない層に向けて掲載コスメを軸としたコンテンツを発信し、該当コスメを紹介している動画の視聴を促します。`
        },
        {
          title: '公式クリエイター申請方法',
          text: `公式クリエイターへの申請については<a href="https://docs.google.com/forms/d/e/1FAIpQLScG_Ci2zP3Fj5adp5u8gF0RMK8oP0HkkNMLthAW3X5GAyT_1w/viewform?usp=sf_link" target=”_blank”>こちらの事前申請フォーム</a>からお願い致します。`
        },
        {
          title: 'Cramel（クラメル）の由来',
          text: `Cram: 詰め込む[英語]＋mel: 蜜[ラテン語]
          の意味をもつ造語です。「良質な知識が詰め込まれてる場」という想いを込めてCramelです。
          `
        },

      ]
    }),

    methods: {
    routerPush(view: string): void {
      this.$router.push({ name: view })
    }
  }
})
