



























































import Vue from 'vue'


export default Vue.extend({
  name: 'SearchVideo',

  props: {
    handleVideos: [] as any
  },

  data: () => ({
  }),

  methods: {
    pushVideoPage(v: number) {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    },

    pushProductPage(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    }
  }
})
