






























































































































































































































































import Vue from 'vue'
// import CarouselImage from '@/components/CarouselImage.vue'
import store from '@/store'
import { DB } from '@/firebase/db'
import firebase from 'firebase'

export default Vue.extend({
  name: 'VideoList',

  data: () => ({
    videos: [] as any,
    channel: [] as any,
    products: [] as any,
    videoModalId: {} as any,
    playerVars: {
      playsinline: 1,
      autoplay: 1,
      start: 0
    }
  }),

  created: function() {
    // if(window.matchMedia('(display-mode: standalone)').matches){
    // }
    this.fetchVideos()
    // window.scrollTo({
    //   top: 0
    // })
  },

  // mounted: function() {
  //   console.log(this.$youtube.getIdFromUrl("https://youtu.be/jRqCkAyWaiI"))
  // },

  methods: {
    fetchVideos (): void {
      const videoArray = [] as any
      const arr = [] as any
      DB.collection('videoUnique')
      .orderBy('publishedAt', 'desc')
      .limit(30)
      .get()
      .then(querySnapShot => {
        querySnapShot.forEach(doc => {
          videoArray.push(doc.data())
        })
      })
      .then(() => {
        this.videos = videoArray
        videoArray.forEach((e: any) => {
          this.fetchProductData(e.id)
          this.existsLogOfPWALaunch()
        })
      })
    },

    fetchProductData(id: string): any {
      const productArray = [] as any
      DB.collection('videos')
      .where('videoId','==', id)
      .limit(4)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          productArray.push(doc.data())
        })
        this.products.push(productArray)
      })
    },

    publishedAt(datetime: Date) {
      const from = new Date(datetime);
      const diff = new Date().getTime() - from.getTime();
      const elapsed = new Date(diff);

      if (elapsed.getUTCFullYear() - 1970) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
      } else if (elapsed.getUTCMonth()) {
        return elapsed.getUTCMonth() + 'ヶ月前';
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + '日前';
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + '時間前';
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + '分前';
      } else {
        return elapsed.getUTCSeconds() + 'たった今';
      }
    },

    closeVideoModal (index: number, youtube: any) {
      const youtubePlayer: any = this.$refs[youtube]
      youtubePlayer[0].player.stopVideo()
      if (!this.videoModalId[index]) {
        this.$set(this.videoModalId, index, true)
      } else {
        this.videoModalId[index] = !this.videoModalId[index]
      }
    },

    convertTime(time: string) {
      const timeArray = time.split(':')
      return Number(timeArray[0]) * 60 + Number(timeArray[1])
    },

    openVideoModal (time: string) {
      this.playerVars.start = this.convertTime(time)
      this.$ga.event('openVideoModalAtVideoList', 'push')
    },

    ready(youtube: string) {
      // console.log('ready')
      const youtubePlayer: any = this.$refs[youtube]
      youtubePlayer[0].player.mute()
      youtubePlayer[0].player.playVideo()
    },

    existsLogOfPWALaunch() {
      const cookie = this.getCookie()
      if (this.$route.query['mode'] == 'standalone') {
        const docRef = DB.collection('cookies').doc(cookie)
        docRef.get()
        .then((doc) => {
          // console.log(doc.exists)
          if (!doc.exists) {
            docRef
            .set({
              cookie: cookie,
              createdAt: new Date(),
            })
            .then(() => {
              this.$ga.event('launchPWA', 'launch')
            })
          }
          if (doc.exists) {
            docRef
            .set({
              updatedAt: new Date()
            },
            { 
              merge: true
            })
          }
        })
      }
    },

    getCookie(): string {
      const cookies = document.cookie
      // console.log(document.cookie)
      const cookiesArray = cookies.split(';')
      let cookie = ''
      cookiesArray.forEach((c: any) => {
        const cArray = c.split('=')
          if( cArray[0] == '_ga'){
            cookie = cArray[1]
          }
      })
      return cookie
    },

    ended() {
      // console.log('ended')
    },
 
    openWindow (url: string) {
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    pushToChannelPage(v: number): void {
      // console.log('')
      // this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToProductPage(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToVideoPage(v: number): void {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    },

    pushToSortedVideoListlPage(v: string): void {
      this.$router.push({ name: 'SortedVideoList', params: { id: v }})
    },
  }
})
