import { render, staticRenderFns } from "./SortedProductList.vue?vue&type=template&id=0e886820&scoped=true&"
import script from "./SortedProductList.vue?vue&type=script&lang=ts&"
export * from "./SortedProductList.vue?vue&type=script&lang=ts&"
import style0 from "./SortedProductList.vue?vue&type=style&index=0&id=0e886820&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e886820",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBadge,VBtn,VCol,VContainer,VImg,VProgressCircular,VRow,VSelect,VTab,VTabs,VTabsSlider})
