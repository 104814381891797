



























































import Vue from 'vue'
import store from '@/store'
import { DB } from '@/firebase/db'

export default Vue.extend({
  name: 'Category',

  data: () => ({
    ready: false,
    largeCategories: [
      {
        name: 'メイクアップ',
        id: "1",
        categories: [] as any
      },
      {
        name: 'ベースメイク',
        id: "2",
        categories: [] as any
      },
      {
        name: 'リップ・グロス',
        id: "3",
        categories: [] as any
      },
      {
        name: 'ヘアケア',
        id: "7",
        categories: [] as any
      },
      {
        name: 'ネイル・ネイルケア',
        id: "4",
        categories: [] as any
      },
      {
        name: 'スキンケア・基礎化粧品',
        id: "5",
        categories: [] as any
      },
      {
        name: 'ボディケア',
        id: "6",
        categories: [] as any
      },
      {
        name: '香水',
        id: "8",
        categories: [] as any
      },
      {
        name: '美容グッズ',
        id: "9",
        categories: [] as any
      }
    ]
  }),

  created: function () {
    this.fetchProducts()
    window.scrollTo({
      top: 0
    })
  },

  methods: {
    fetchProducts (): void {
      this.largeCategories.forEach((largeCategory) => {
        DB.collection('categories')
        .where('largeCategoryId', '==', largeCategory.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            largeCategory.categories.push(doc.data())
          })
        })
      })
    },

    routerPush(v: number) {
      this.$router.push({ name: 'ProductList', params: { id: v.toString() }})
    }
  }
})
