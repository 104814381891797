
















































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import firebase from 'firebase'
import axios from 'axios'
import SuggestVideoList from '@/components/SuggestVideoList.vue'
import SearchProduct from '@/components/SearchProduct.vue'
import YoutubePlayer from '@/components/YoutubePlayer.vue'

export default Vue.extend({
  name: 'IndividualProductVideo',

  components: {
    SuggestVideoList,
    SearchProduct,
    YoutubePlayer
  },

  data: () => ({
    video: {} as any,
    videoUniques: [] as any,
    videoId: '',
    time: '',
    timerId: 0,
    product: {} as any,
    productId: '',
    videoModalId: {} as any,
    isShow: {} as any,
    isFixed: false,
    isDisplay: true,
    isFetch: false,
    uid: '',
    commentId: '',
    onAuthState: false,
    existsCosme: false,
    addToCosmePouchButtonLabel: 'コスメポーチに追加',
    comments: [] as any,
    channels: [] as any,
    sameBrandProduct: [] as any,
    sameCategoryProduct: [] as any,
    otherModel: [] as any,
    currentSort: 'popular',
    currentModelNumber: '',
    numberOfVideoGood: 0 as any,
    isAnonymous: false,
    sortItems: [
        {
          title: '人気順',
          value: 'popular',
        },
        {
          title: '名前順',
          value: 'name',
        }
      ],
  }),

  computed: {
    breadcrumbs: function() {
      const breadcrumbs = [
        {
          text: 'TOP',
          disabled: false,
          href: '/'
        },
        {
          text: this.product.brand,
          disabled: false,
          href: `/brandproduct/${this.product.brandId}`
        },
        {
          text: this.product.name,
          disabled: false,
          href: `/product/${this.product.id}`
        },
        {
          text: 'レビュー動画一覧',
          disabled: true
        },
      ]
      return breadcrumbs
    },

    goodButtonColor: function() {
      let color = ''
      if (this.numberOfVideoGood >= 0) {
        color = '#E2526E'
      }
      if (this.numberOfVideoGood == 0) {
        color = '#CCCCCC'
      }
      return color
    },

    goodButtonText: function() {
      let text = ''
      if (this.numberOfVideoGood >= 0) {
        text = `この動画が参考になったら
          いいねを押してYouTuberを応援しよう！`
      }
      if (this.numberOfVideoGood == 10) {
        text = `THANK YOU！`
      }
      return text
    }
  },

  created: function() {
    this.onAuthStateChanged()
    this.fetchVideoData()
    this.fetchProduct()
    this.fetchComments()
    window.scrollTo({
      top: 0
    })
    this.videoId = this.$route.params.id.split('&')[0]
    this.time = this.$route.params.id.split('&')[1]
    this.productId = this.$route.params.id.split('&')[2]
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  watch: {
    $route (to, from) {
      this.initialize()
      this.fetchVideoData()
      this.fetchProduct()
      this.fetchComments()
      window.scrollTo({
        top: 0
      })
    },

    currentSort: function(newVal, oldVal) {
      if(newVal == 'popular'){
        this.otherModel.sort((a: any, b: any) => {
          if (a.index < b.index) {
            return -1
          } else {
            return 1
          }
       })
      }
      if(newVal == 'name'){
        this.otherModel.sort((a: any, b: any) => {
          if (a.product.modelNumber < b.product.modelNumber) {
            return -1
          } else {
            return 1
          }
       })
      }
    }
  },

  methods: {
    initialize(){
      this.video = {}
      this.videoUniques = []
      this.otherModel = []
      this.product = ''
    },

    handleScroll() {
      if(window.scrollY>500){
        this.isFixed = true
        this.isDisplay = false
      } else {
        this.isFixed = false
        this.isDisplay = true
      }
    },
    
    fetchVideoData() {
      const videoId = this.$route.params.id.split('&')[0]
      DB.collection('videoUnique')
      .doc(videoId)
      .get()
      .then((doc) => {
        this.video = doc.data()
      })
      .then(() => {
        this.fetchVideoUnique(this.video.personalColorSeason, this.video.id)
      })
    },
    
    fetchProduct (): void {
      const id = this.$route.params.id.split('&')[2]
      DB.collection('products')
      .where('id', '==', id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.product = doc.data()
        })
      })
      .then(() => {
        this.fetchOtherModel(this.product.name)
        this.fetchSameBrandProduct(this.product.brand, this.product.categoryId)
        this.fetchSameCategoryProduct(this.product.brand, this.product.categoryId)
      })
      .then(() => {
        this.isFetch = true
        document.title = `【${this.product.brand}/${this.product.name}/${this.product.modelNumber}】人気YouTuberの動画レビューまとめ｜Cramel（クラメル）`
      })
    },

    fetchSameBrandProduct(brand: string, categoryId: number): void {
      const sameBrandProductArray = [] as any
      const array = [] as any
      DB.collection('products')
      .where('brand', '==', brand)
      .where('categoryId', '==', categoryId)
      .orderBy('times', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if(doc.id != this.$route.params.id) {
            array.push(doc.data())
          }
        })
      })
      .then(() => {
        for(let i = 0; i < 3; i++) {
          if (array[i]) {
            sameBrandProductArray.push(array[i])
          }
        }
        this.sameBrandProduct = sameBrandProductArray
      })
    },

    fetchSameCategoryProduct(brand: string, categoryId: number): void {
      const sameCategoryProductArray = [] as any
      const array = [] as any
      DB.collection('products')
      .where('categoryId', '==', categoryId)
      .orderBy('times', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if(doc.id != this.$route.params.id && doc.data().brand != brand) {
            array.push(doc.data())
          }
        })
      })
      .then(() => {
        for(let i = 0; i < 3; i++) {
          if (array[i]) {
            sameCategoryProductArray.push(array[i])
          }
        }
        this.sameCategoryProduct = sameCategoryProductArray
      })
    },

    fetchOtherModel (name: string): void {
      const arr = [] as any
      let index = 0
      DB.collection('products')
      .where('name', '==', name)
      .orderBy('times', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.otherModel.push(
            {
              product: doc.data(),
              index: index
            }
          )
          index++
        })
      })
    },

    publishedAt(datetime: Date) {
      const from = new Date(datetime);
      const diff = new Date().getTime() - from.getTime();
      const elapsed = new Date(diff);

      if (elapsed.getUTCFullYear() - 1970) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
      } else if (elapsed.getUTCMonth()) {
        return elapsed.getUTCMonth() + 'ヶ月前';
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + '日前';
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + '時間前';
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + '分前';
      } else {
        return elapsed.getUTCSeconds() + 'たった今';
      }
    },

    fetchVideoUnique (personalColorSeason: string, videoId: string): void {
      const videoUniqueArray = [] as any
      DB.collection('videoUnique')
      .orderBy('publishedAt', 'desc')
      .limit(20)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if(videoId != doc.id){
            videoUniqueArray.push(doc.data())
          }
        })
        this.videoUniques = this.shuffleFisherYates(videoUniqueArray)
      })
    },

    fetchComments (): void {
      const videoId = this.$route.params.id.split('&')[0]
      const id = this.$route.params.id.split('&')[2]
      const videoArray = [] as any
      const commentArray = [] as any
      const arr = [] as any
      DB.collection('videos')
      .where('productId', '==', id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          videoArray.push(doc.data())
          commentArray.push(doc.data())
          // if(doc.data().comment){
          //    commentArray.push(doc.data())
          // }
        })
        const sortCommentLength = commentArray.sort((a: any, b: any) => {
            if (a.comment.length < b.comment.length) {
              return -1
            } else {
              return 1
            }
          })
        this.comments = sortCommentLength.sort((a: any, b: any) => {
          if ((b.videoId === videoId)) {
            return 1
          } else {
            return -1
          }
        })
      })
      .then(() => {
        videoArray.forEach((e: any) => {
          arr.push(e.channelId)
        })
      })
      .then(() => {
        Array.from(new Set(arr)).forEach((e: any) => {
          this.fetchChannels(e)
        })
      })
      .then(() => {
        this.commentId = this.comments[0].id
        this.toggleShowPost(videoId)
      }) 
    },

    fetchChannels (id: string): void {
      DB.collection('channels')
      .where('id', '==', id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.channels.push(doc.data())
        })
      })
    },

    shuffleFisherYates(arr: any[]): any[] {
      const newArray = arr
      for(let i = newArray.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * (i + 1));
        const tmp = newArray[i];
        newArray[i] = newArray[j];
        newArray[j] = tmp;
      }
      return newArray
    },

    changeVideo(video: any) {
      const videoId = video.videoId
      this.commentId = video.id
      this.videoId = videoId
      this.toggleShowPost(video.videoId)
      DB.collection('videoUnique')
      .doc(videoId)
      .get()
      .then((doc) => {
        this.video = doc.data()
        this.time = video.time
      })
      .then(() => {
        this.checkIfProductDataExistsInUsersGoodVideo(this.uid)
      })
    },

    truncate(str: string, len: number){
      return str.length <= len ? str: (str.substr(0, len)+"...");
    },

    openWindowAmazon (url: string) {
      this.$ga.event('amazonAffiliatelink', 'push')
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    addToCosmePouch() {
      if(this.uid && !this.isAnonymous) {
        this.$ga.event('toCosmePouch', 'push')
        DB.collection('users')
        .doc(this.uid)
        .collection('cosmePouch')
        .doc(this.productId)
        .set(this.product)
        .then(() => {
          this.existsCosme = true
          this.addToCosmePouchButtonLabel = 'コスメポーチに追加済み'
        })
      } else {
        this.$ga.event('toCosmePouchAnonymous', 'push')
        this.pushToSignin()
      }
    },

    onAuthStateChanged() {
      firebase.auth().onAuthStateChanged( user => {
        if (user) {
          this.uid = user.uid
          this.isAnonymous = user.isAnonymous
          this.checkIfProductDataExistsInUsersCosmepouch(user.uid)
          this.checkIfProductDataExistsInUsersGoodVideo(user.uid)
        } else {
          this.addToCosmePouchButtonLabel = 'コスメポーチに追加'
          firebase.auth().signInAnonymously()
            .then(() => {
              firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                  this.uid = user.uid
                  this.isAnonymous = user.isAnonymous
                }
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },

    checkIfProductDataExistsInUsersCosmepouch (uid: string): void {
      DB.collection('users')
      .doc(uid)
      .collection('cosmePouch')
      .doc(this.productId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.existsCosme = true
          this.addToCosmePouchButtonLabel = 'コスメポーチに入っています'
        } else {
          this.addToCosmePouchButtonLabel = 'コスメポーチに追加'
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    checkIfProductDataExistsInUsersGoodVideo (uid: string): void {
      DB.collection('users')
      .doc(uid)
      .collection('goodVideo')
      .doc(this.videoId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data: any = doc.data()
          this.numberOfVideoGood = data.goodCount
        } else {
          this.numberOfVideoGood = 0
          console.log('doc is nothing')
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    changeParams (id: string) {
      this.channels = []
      this.existsCosme = false
      this.sameBrandProduct = []
      this.sameCategoryProduct = []
      this.onAuthStateChanged()
      this.fetchProduct()
      this.fetchComments()
      window.scrollTo({
        top: 0
      })
    },

    toggleShowPost(id: string){
      this.$ga.event('pushReview', 'push')
      this.$set(this.isShow, id, true)
      this.comments.forEach((e: any) => {
        if (e.videoId == id) {
          e['class'] = 'highlight'
        }
        if (e.videoId != id) {
          e['class'] = ''
        }
      })
    },

    incrementNumberOfVideoGood(section: string) {
      if (this.numberOfVideoGood < 10) {
        this.$ga.event(section, 'push')
        this.numberOfVideoGood += 1
        this.video.goodCountAtCramel += 1
        const increment = firebase.firestore.FieldValue.increment(1)
        const docRefUsersGoodVideo = DB.collection('users')
          .doc(this.uid)
          .collection('goodVideo')
          .doc(this.videoId)
        const docRefVideos = DB.collection('videos')
          .doc(String(this.commentId))
        const docRefVideoUnique = DB.collection('videoUnique')
          .doc(this.videoId)
      
        docRefUsersGoodVideo
        .set({
          goodCount: increment
        },
        { 
          merge: true
        })

        docRefVideos
        .set({
          goodCountAtCramel: increment
        },
        { 
          merge: true
        })

        docRefVideoUnique
        .set({
          goodCountAtCramel: increment
        },
        { 
          merge: true
        })

        this.comments.forEach((e: any, i: number) => {
          if(e.id == this.commentId) {
            this.comments[i].goodCountAtCramel += 1
          }
        })
      }
    },

    routerPush(v: number) {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToYoutube (url: string) {
      this.$ga.event('pushYoutube', 'push')
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    pushToProductPage(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToVideoPage(v: number): void {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    },

    pushToYoutubersProduct(v: number) {
      console.log('')
      this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToBrandProductPage(v: number): void {
      this.$router.push({ name: 'BrandProduct', params: { id: v.toString() }})
    },

    pushToSignin() {
      this.$router.push({ name: 'Signin' })
    }
  }
})
