





















































import Vue from 'vue'
import { DB } from '@/firebase/db'

export default Vue.extend({
  name: 'BrandList',

  data: () => ({
    brands: [] as any,
    isFetch: false
  }),

  created: function() {
    this.fetchBrands()
    window.scrollTo({
      top: 0
    })
  },

  methods: {

    fetchBrands (): any {
      const brands = [] as any
      DB.collection('brands')
      .orderBy('brandName', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().times != 0) {
            brands.push(doc.data())
          }
        })
      })
      .then(() => {
        this.brands = brands
        this.isFetch = true
      })
    },

    pushToBrandProductPage(v: number): void {
      this.isFetch = false
      // console.log('ssss')
      this.$router.push({ name: 'BrandProduct', params: { id: v.toString() }})
    }
  }
})
