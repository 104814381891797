




















































































































































































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import firebase from 'firebase'
import { Auth } from '@/firebase/auth'
// import axios from 'axios'

interface Pouch {
  uid: string;
  thumbnail: string;
  name: string;
  selfIntroduction: string;
  products: number[];
  brands: string[];
  email: string;
}

export default Vue.extend({
  name: 'UsersProduct',

  data: () => ({
    editedName: '',
    editedEmail: '',
    editedSelfIntroduction: '',
    categoryName: '',
    productIdArray: [] as any,
    products: [] as any,
    brands: [] as any,
    userInfo: {},
    existsProduct: true,
    dialog: false,
    isEdit: false,
    isAnonymous: false,
    rules: {
      required: (v: any) => !!v || '未入力です。',
      min: (v: string) => v.length >= 8 || '8文字以上必要です。',
      emailMatch: () => (`The email and password you entered don't match`),
      email: (v: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'メールアドレスの形式が正しくありません。'
      }
    },
    shareSheet: false,
    userPage: 'https://cramel.jp/cosmepouch/',
    tiles: [
      {
        name: 'twitter',
        icon: 'mdi-twitter',
        title: 'コスメポーチをTwitterでシェア',
        color:'#00acee'
      },
      {
        name: 'line',
        icon: 'mdi-chat ',
        title: 'コスメポーチをLINEでシェア',
        color:'#00B900'
      },
      {
        name: 'copy',
        icon: 'mdi-content-copy',
        title: 'コスメポーチのURLをコピー',
        color:'grey'
      },
    ]
  }),
  computed: {
    //inputで入力した内容ではなく、ここでフォーマットしたテキストがコピー対象
    formattedTitle(): string {
      const uid = (this.userInfo as any).uid
      return `${this.userPage}${uid}`
    },
    readyEdit: function() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(this.editedEmail) && this.editedName != ''
    }
  },
  created: function() {
    this.onAuthStateChanged()
    window.scrollTo({
      top: 0
    })
  },

  methods: {

    onAuthStateChanged(): void {
      this.products = []
      firebase.auth().onAuthStateChanged( user => {
        if (user && !user.isAnonymous) {
          console.log(user)
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          this.fetchUserData(user.uid)
          this.fetchProductIdInUsersSubCollection(user.uid)
        } else {
          this.pushSignin()
        }
      })
    },

    fetchUserData (uid: string): void {
      DB.collection('users')
        .doc(uid)
        .get()
        .then((doc) => {
          const data = doc.data() as any
          this.userInfo = data
          this.editedName = data.name
          this.editedEmail = data.email
          this.editedSelfIntroduction = data.selfIntroduction
        })
    },

    fetchProductIdInUsersSubCollection (uid: string): void {
      const productIdArray = [] as any
      DB.collection('users')
      .doc(uid)
      .collection('cosmePouch')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          productIdArray.push(doc.data().id)
        })
        this.productIdArray = productIdArray
      })
      .then(() => {
        this.fetchProducts()
        // this.setCosmeDataInFirestore()
      })
    },

    fetchProducts (): void {
      this.productIdArray.forEach((v: number) => {
        DB.collection('products')
        .doc(String(v))
        .get()
        .then((doc) => {
          this.products.push(doc.data())
          this.products.sort((a: any, b: any) => {
            if (a.sortId < b.sortId) {
              return -1
            } else {
              return 1
            }
          })
        })
        .then(() => {
          this.createBrandArray()
        })
        .then(() => {
          this.setCosmeDataInFirestore()
        })
      })
    },

    createBrandArray(): void {
      const array = this.products.map((obj: any) => {
        const brandArray = obj.brand
        return brandArray
      })
      this.brands = Array.from(new Set(array))
    },

    updatedUserData(): void{
      const uid = (this.userInfo as any).uid
      DB.collection('users')
      .doc(uid)
      .update(
        {
          name: this.editedName,
          email: this.editedEmail,
          selfIntroduction: this.editedSelfIntroduction
        }
      ).then(() => {
        this.onAuthStateChanged()
        this.dialog = false
      })
    },

    signout(): void {
      Auth.signOut()
    },

    pushSignin(): void {
      this.$router.push({ name: 'Signin' })
    },

    pushToProduct(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToRankingList(v: number): void {
      this.$router.push({ name: 'RankingList' })
    },

    shareURL(button: string) {
      this.setCosmeDataInFirestore()
      const name = (this.userInfo as any).name
      const uid = (this.userInfo as any).uid
      if (button == 'twitter') {
        this.shareByTwitter(name, uid)
      }
      if (button == 'line') {
        this.shareByLine(name, uid)
      }
      if (button == 'copy') {
         this.copyTexts(uid)
      }
      this.shareSheet = false
    },

    shareByTwitter(name: string, uid: string) {
      const url = `https://twitter.com/intent/tweet?text=クラメルで${name}さんのコスメポーチをみてみよう！%20%23クラメル&url=${this.userPage}${uid}`
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    shareByLine(name: string, uid: string) {
      const url = `http://line.me/R/msg/text/?クラメルで${name}さんのコスメポーチをみてみよう！${this.userPage}${uid}`
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    copyTexts(uid: string): void {
      if (this.isIOS()) {
        const doc: HTMLInputElement = document.getElementById(
          "copy-text"
        ) as HTMLInputElement;
        const selected = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(doc);
        selected!.removeAllRanges();
        selected!.addRange(range);
        document.execCommand("copy");
      } else {
        const formattedText = `${this.formattedTitle}`;
        navigator.clipboard.writeText(formattedText);
      }
      alert(`URLをクリップボードにコピーしました。`)
    },

    setCosmeDataInFirestore() {
      const uid = (this.userInfo as any).uid
      const thumbnail = (this.userInfo as any).thumbnail
      const name = (this.userInfo as any).name
      const selfIntroduction = (this.userInfo as any).selfIntroduction
      const key = uid.charCodeAt(1)+uid.charCodeAt(5)
      const email = this.caesar((this.userInfo as any).email, key)

      const data: Pouch = {
        uid: uid,
        thumbnail: thumbnail,
        name: name,
        selfIntroduction: selfIntroduction,
        products: this.productIdArray,
        brands: this.brands,
        email: email != '' ? email : ''
      }
      DB.collection('cosmePouch')
      .doc(uid)
      .set(data)
    },

    editCosmePouch() {
      this.isEdit = !this.isEdit
    },

    updateCosmePouch(id: number) {
      this.setCosmePouchDataInFirestore(id)
      this.initializeData(id)
      this.createBrandArray()
    },

    setCosmePouchDataInFirestore(id: number) {
      const uid = (this.userInfo as any).uid
      const array = this.productIdArray.filter((n: any) => n !== id)
      this.products = this.products.filter((n: any) => n.id !== id)
      this.createBrandArray()

      DB.collection('users')
      .doc(uid)
      .collection('cosmePouch')
      .doc(String(id))
      .delete()
      DB.collection('cosmePouch')
      .doc(uid)
      .set(
        {
          products: array,
          brands: this.brands
        },
        {
          merge: true
        }
      )
    },

    initializeData(id: number){
      this.productIdArray = this.productIdArray.filter((n: number) => n !== id)
      this.products = this.products.filter((n: any) => n.id !== id)
    },

    isIOS() {
      const agent = window.navigator.userAgent;
      console.log(agent)
      return agent.indexOf("iPhone") != -1 || agent.indexOf("iPad") != -1 || agent.indexOf("Mac") != -1;
    },

    caesar(val: string, key: number) {
      // console.log(val)
      const encodeURI = encodeURIComponent(val)
      let result = ""
      for (let i = 0; i < encodeURI.length; i++) {
        result += String.fromCharCode(encodeURI.charCodeAt(i) + key)
      }
      return result
    }
  }
})
