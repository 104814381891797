






































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import axios from 'axios'
// import CarouselImage from '@/components/CarouselImage.vue'

interface Channel {
  id: '';
  name: '';
  channelId: '';
  image: '';
  subscriberCount: 0;
  viewCount: 0;
  videoCount: 0;
}

export default Vue.extend({
  name: 'ChannelList',

  // components: {
  //   CarouselImage
  // },

  data: () => ({
    ready: false,
    channels: [] as any
  }),

  created: function() {
    this.fetchChannels()
    // window.scrollTo({
    //   top: 0
    // })
  },

  computed: {
    sortedChannels: function() {
        return this.channels.slice().sort((a: any, b: any) => {
            return b.subscriberCount - a.subscriberCount
        })
    }
  },

  methods: {
    fetchChannels (): any {
      const array = [] as any
      DB.collection('channels')
      .orderBy('likeRate', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          array.push(doc.data())
        })
        this.channels = array
      })
    },

    pushToSortedChannelList(tag: string) {
      this.$router.push({
        name: 'SortedChannelList',
        params: {
          tag: tag
        }
      })
    },

    routerPush(v: number) {
      console.log('')
      this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    openWindow (url: string) {
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    }
  }
})
