























































































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
import firebase from 'firebase'
import { Auth } from '@/firebase/auth'
// import axios from 'axios'

export default Vue.extend({
  name: 'UsersProduct',

  data: () => ({
    categoryName: '',
    productIdArray: [] as any,
    products: [] as any,
    userInfo: {},
    existsProduct: true,
    shareSheet: false,
    userCosmePage: 'https://cramel.jp',
    tiles: [
      {
        name: 'twitter',
        icon: 'mdi-twitter',
        title: 'コスメポーチをTwitterでシェア',
        color:'#00acee'
      },
      {
        name: 'line',
        icon: 'mdi-chat ',
        title: 'コスメポーチをLINEでシェア',
        color:'#00B900'
      },
      {
        name: 'copy',
        icon: 'mdi-content-copy',
        title: 'コスメポーチのURLをコピー',
        color:'grey'
      },
    ]
  }),

  computed: {
    formattedTitle(): string {
      return `${this.userCosmePage}${this.$route.path}`
    }
  },
  
  created: function() {
    this.existsRouteId()
    window.scrollTo({
      top: 0
    })
  },

  methods: {

    existsRouteId(): void {
      if (this.$route.params.id) {
        this.fetchUserData(this.$route.params.id)
      } else {
        this.pushSignin()
      }
    },

    fetchUserData (v: string): void {
      DB.collection('cosmePouch')
        .doc(v)
        .get()
        .then((doc) => {
          const data = doc.data() as any
          this.userInfo = data
          this.productIdArray = data.products
        })
        .then(() => {
        this.fetchProducts()
      })
    },

    fetchProducts (): void {
      this.productIdArray.forEach((v: number) => {
        DB.collection('products')
        .doc(String(v))
        .get()
        .then((doc) => {
          this.products.push(doc.data())
          this.products.sort((a: any, b: any) => {
            if (a.sortId < b.sortId) {
              return -1
            } else {
              return 1
            }
          })
        })
      })
    },

    signout(): void {
      Auth.signOut()
    },

    pushSignin(): void {
      this.$router.push({ name: 'Signin' })
    },

    pushToProduct(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToRankingList(v: number): void {
      this.$router.push({ name: 'RankingList' })
    },

    shareURL(button: string) {
      if (button == 'twitter') {
        this.shareByTwitter(name)
      }
      if (button == 'line') {
        this.shareByLine(name)
      }
      if (button == 'copy') {
         this.copyTexts()
      }
      this.shareSheet = false
    },

    shareByTwitter(name: string) {
      const url = `https://twitter.com/intent/tweet?text=クラメルで${name}さんのコスメポーチをみてみよう！%20%23クラメル&url=${this.userCosmePage}${this.$route.path}`
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    shareByLine(name: string) {
      const url = `http://line.me/R/msg/text/?クラメルで${name}さんのコスメポーチをみてみよう！${this.userCosmePage}${this.$route.path}`
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    copyTexts(): void {
      if (this.isIOS()) {
        const doc: HTMLInputElement = document.getElementById(
          "copy-text-pouch"
        ) as HTMLInputElement;
        const selected = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(doc);
        selected!.removeAllRanges();
        selected!.addRange(range);
        document.execCommand("copy");
      } else {
        const formattedText = `${this.formattedTitle}`;
        navigator.clipboard.writeText(formattedText);
      }
      alert(`URLをクリップボードにコピーしました。`)
    },

    isIOS() {
      const agent = window.navigator.userAgent;
      // console.log(agent)
      return agent.indexOf("iPhone") != -1 || agent.indexOf("iPad") != -1 || agent.indexOf("Mac") != -1;
    },
  }
})
