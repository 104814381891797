































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
// import CarouselImage from '@/components/CarouselImage.vue'
import store from '@/store'
import { DB } from '@/firebase/db'
import firebase from 'firebase'
import CarouselVueFlickity from '@/components/CarouselVueFlickity.vue'
import Flickity from 'vue-flickity-2';
import 'vue-flickity-2/dist/vue-flickity-2.css';

export default Vue.extend({
  name: 'ResultAutumn',

  components: {
    CarouselVueFlickity,
    Flickity
  },

  data: () => ({
    imageAutumn: require('@/assets/images/img_autumn.jpg'),
    imageColorAutumn: require('@/assets/images/color_autumn_clothes.png'),
    channels: [] as any,
    autumnCosmeEye: [] as any,
    autumnCosmeCheek: [] as any,
    autumnCosmelip: [] as any,
    autumnYoutuber: [] as any,
    videos: [] as any,
    flickityOptions: {
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true
    },
  }),

  created: function() {
    // if(window.matchMedia('(display-mode: standalone)').matches){
    // }
    this.fetchAutumnCosmeData()
    this.fetchVideos()
  },

  methods: {
    fetchVideos (): void {
      DB.collection('videoUnique')
      .where('tags', 'array-contains', '秋' )
      .orderBy('publishedAt', 'desc')
      .limit(5)
      .get()
      .then(querySnapShot => {
        querySnapShot.forEach(doc => {
          this.videos.push(doc.data())
        })
      })
    },

    fetchAutumnCosmeData(): void {
      DB.collection('products')
      .where('categoryId', '==', "1" )
      .where('tags', 'array-contains', '秋' )
      .orderBy('times', 'desc')
      .limit(5)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          this.autumnCosmeEye.push(doc.data())
        })
      })
      DB.collection('products')
      .where('categoryId', '==', "16" )
      .where('tags', 'array-contains-any', ['秋', 'イエベ'] )
      .orderBy('times', 'desc')
      .limit(5)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          this.autumnCosmeCheek.push(doc.data())
        })
      })
      DB.collection('products')
      .where('categoryId', '==', "57" )
      .where('tags', 'array-contains', '秋' )
      .orderBy('times', 'desc')
      .limit(5)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          this.autumnCosmelip.push(doc.data())
        })
      })
      DB.collection('channels')
      .where('tags', 'array-contains', '秋' )
      .orderBy('subscriberCount', 'desc')
      .limit(5)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach(doc =>{
          this.autumnYoutuber.push(doc.data())
        })
      })
    },

    publishedAt(datetime: Date) {
      const from = new Date(datetime);
      const diff = new Date().getTime() - from.getTime();
      const elapsed = new Date(diff);

      if (elapsed.getUTCFullYear() - 1970) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
      } else if (elapsed.getUTCMonth()) {
        return elapsed.getUTCMonth() + 'ヶ月前';
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + '日前';
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + '時間前';
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + '分前';
      } else {
        return elapsed.getUTCSeconds() + 'たった今';
      }
    },

    convertTime(time: string) {
      const timeArray = time.split(':')
      return Number(timeArray[0]) * 60 + Number(timeArray[1])
    },

    truncate(str: string, len: number){
        return str.length <= len ? str: (str.substr(0, len)+"...");
    },
 
    openWindow (url: string) {
      window.open(url, '_blank', 'width=1024,height=768,scrollbars=yes,resizable=yes')
    },

    pushToChannelPage(v: number): void {
      console.log('')
      // this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToProductPage(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    },

    pushToSortedVideoListlPage(v: string): void {
      this.$router.push({ name: 'SortedVideoList', params: { id: v }})
    },

    pushToSortedChannelList(tag: string) {
      this.$router.push({ name: 'SortedChannelList', params: { id: tag }})
    }

  }
})
