







































































































import Vue from 'vue'
import { DB } from '@/firebase/db'
export default Vue.extend({
  name: 'SortedVideoList',

  data: () => ({
    videoUniques: [] as any,
    tag: ''
  }),

  created: function() {
    this.tag = this.$route.params.id
    this.fetchVideoUnique(this.$route.params.id)
    window.scrollTo({
      top: 0
    })
  },

  methods: {

    fetchVideoUnique (tag: string): void {
      const videoUniqueArray = [] as any
      DB.collection('videoUnique')
      .where('tags', 'array-contains', tag)
      .orderBy('publishedAt', 'desc')
      .limit(50)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            this.videoUniques.push(doc.data())
        })
      })
    },
    
    publishedAt(datetime: Date) {
      const from = new Date(datetime);
      const diff = new Date().getTime() - from.getTime();
      const elapsed = new Date(diff);

      if (elapsed.getUTCFullYear() - 1970) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
      } else if (elapsed.getUTCMonth()) {
        return elapsed.getUTCMonth() + 'ヶ月前';
      } else if (elapsed.getUTCDate() - 1) {
        return elapsed.getUTCDate() - 1 + '日前';
      } else if (elapsed.getUTCHours()) {
        return elapsed.getUTCHours() + '時間前';
      } else if (elapsed.getUTCMinutes()) {
        return elapsed.getUTCMinutes() + '分前';
      } else {
        return elapsed.getUTCSeconds() + 'たった今';
      }
    },

    changeParms(tag: string) {
      this.tag = tag
      this.videoUniques = []
      this.fetchVideoUnique(tag)
    },
    
    pushToChannelPage(v: number): void {
      console.log('')
      this.$router.push({ name: 'YoutubersProduct', params: { id: v.toString() }})
    },

    pushToVideoPage(v: number): void {
      this.$router.push({ name: 'VideoProduct', params: { id: v.toString() }})
    },

    pushToProductPage(v: number): void {
      this.$router.push({ name: 'Product', params: { id: v.toString() }})
    }
  }
})
